const parseXMLTag = (xmlString: string, tagNames: string[]) => {
  try {
    // DOMParser is highly optimized for XML parsing and is built into browsers
    const parser = new DOMParser();

    // Parse the XML string into a DOM document
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");

    // Check for parsing errors
    const parserError = xmlDoc.querySelector("parsererror");

    if (parserError) {
      return null;
    }

    // Find the first matching tag
    const matchingTag = tagNames.find((tagName) => {
      const elements = xmlDoc.getElementsByTagName(tagName);
      return elements.length > 0 && elements[0]?.textContent;
    });

    if (matchingTag) {
      const elements = xmlDoc.getElementsByTagName(matchingTag);
      return elements[0]?.textContent ?? null;
    }

    return null;
  } catch (error) {
    return null;
  }
};

export { parseXMLTag };
