import type { CustomInputProps } from "./CustomInput.types";
import type { IconName } from "@urbint/silica";
import { Icon } from "@urbint/silica";
import { useEffect, useRef } from "react";
import Flatpickr from "react-flatpickr";
import classNames from "classnames";
import "flatpickr/dist/flatpickr.min.css";
import "./CustomInput.css";
import { Tooltip } from "@/common/Tooltip";
import { datePickerDefaultClasses } from "./CustomInput.constants";

const CustomInput = ({
  label,
  type,
  minWidth,
  className,
  hasClear,
  clearTooltipText,
  hasError,
  static: staticMode,
  disabled,
  onClose,
  options,
  value,
  onChange,
  placeholder,
  readOnly,
}: CustomInputProps) => {
  const fp = useRef<Flatpickr>(null);

  useEffect(() => {
    if (!fp?.current?.flatpickr) {
      return;
    }

    const instance = fp.current.flatpickr;
    if (instance?.isMobile) {
      if (disabled) {
        instance.mobileInput?.setAttribute("disabled", "true");
      } else {
        instance.mobileInput?.removeAttribute("disabled");
      }
      if (readOnly) {
        instance.mobileInput?.setAttribute("readOnly", "true");
      } else {
        instance.mobileInput?.removeAttribute("readOnly");
      }
    }
  }, [disabled, readOnly, fp]);

  const icon: IconName = type === "time" ? "clock" : "calendar";

  return (
    <div
      className={classNames("relative w-full", className, {
        "bg-white cursor-default": disabled,
      })}
    >
      <Flatpickr
        ref={fp}
        onClose={onClose}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        className={classNames(
          datePickerDefaultClasses,
          {
            "cursor-default text-neutrals-disabled border-borders-disabled":
              disabled,
          },
          {
            "ring-system-error-30 ring-opacity-100 border-system-error-30":
              hasError,
          }
        )}
        disabled={disabled}
        data-input
        options={{
          ...options,
          static: staticMode,
          wrap: true,
          minuteIncrement: 1,
        }}
      >
        {label && (
          <span
            className={classNames(
              "text-sm font-sans left-3 font-semibold cursor-pointer absolute",
              { "cursor-default": disabled }
            )}
            data-toggle
          >
            {label}
          </span>
        )}
        <input
          className={classNames(
            "flatpickr-input py-2 px-3 rounded outline-none appearance-none flex-1 h-9",
            {
              "pl-14": !!label,
              "placeholder-neutrals-primary text-neutrals-primary": !disabled,
              "!cursor-default bg-transparent text-neutrals-disabled placeholder-neutrals-disabled":
                disabled,
            }
          )}
          disabled={disabled}
          readOnly={readOnly}
          style={{ minWidth }}
          data-input
          placeholder={placeholder}
          data-testid="custom-date-input"
        />
        <div
          className={classNames(
            "inline-flex w-5 justify-center cursor-pointer mr-3",
            { "cursor-default": disabled }
          )}
          data-toggle
        >
          <Icon
            className={classNames(" text-lg", {
              "text-neutrals-disabled": disabled,
              "text-neutrals-primary": !disabled,
            })}
            name={icon}
          />
        </div>
        {hasClear && (
          <div
            className={classNames(
              "inline-flex w-5 justify-center cursor-pointer mr-3",
              { "cursor-default": disabled }
            )}
            data-clear
            data-testid="clear-date-icon"
          >
            {clearTooltipText ? (
              <Tooltip title={clearTooltipText} origin="top">
                <Icon className="opacity-22 text-lg" name="close_big" />
              </Tooltip>
            ) : (
              <Icon className="opacity-22 text-lg" name="close_big" />
            )}
          </div>
        )}
      </Flatpickr>
    </div>
  );
};
export { CustomInput };
