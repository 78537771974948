import type { TicketTitleProps } from "./TicketTitleProps.types";
import { ActionLabel, BodyText } from "@urbint/silica";
import cx from "classnames";

const TicketTitle = ({
  title,
  description,
  isNotificationRead,
}: TicketTitleProps) => (
  <div className="inline-flex w-fit max-w-full justify-start pb-1 pr-6 space-x-1">
    <ActionLabel
      className={cx({
        "text-actions-primary": !isNotificationRead,
      })}
    >
      {title}
    </ActionLabel>
    {description ? (
      <BodyText className="truncate">{` - ${description}`}</BodyText>
    ) : null}
  </div>
);

export { TicketTitle };
