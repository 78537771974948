import type { NotificationItemProps } from "./NotificationItem.types";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { Icon } from "@urbint/silica";
import { useDeleteNotification, useMarkNotificationAsRead } from "@/api";
import { formatRelativeTime } from "@/format";
import { Button } from "@/common/Button";
import { Tooltip } from "@/common/Tooltip";
import { useToasts } from "@/components/Toasts";
import { TicketNotification } from "./TicketNotification";
import { TicketTitle } from "./TicketTitle";

const NotificationItem = ({
  notification,
  currentUser,
}: NotificationItemProps) => {
  const history = useHistory();
  const { mutate: readNotification } = useMarkNotificationAsRead();
  const { addToast } = useToasts();

  const { mutate: deleteNotification } = useDeleteNotification(
    notification.id,
    addToast
  );

  const { read, ticketId, timestamp, title, description } = notification;

  const onClick = () => {
    if (!read && currentUser.canMarkNotificationRead) {
      readNotification({ notification });
    }
    history.push(`/ticket/${ticketId}`);
  };
  return (
    <div className="relative">
      <Button
        className="absolute top-1.5 right-3 z-20"
        onClick={() => {
          deleteNotification();
        }}
      >
        <Tooltip title="Delete Notification" origin="left">
          <Icon name="close_big" />
        </Tooltip>
      </Button>
      <Button
        onClick={onClick}
        className={cx(
          "!p-0 font-normal w-full before:absolute before:w-2 before:h-2 before:rounded-full before:left-3 before:top-4.5 z-10",
          {
            "before:bg-actions-primary": !read,
          }
        )}
      >
        <div className="flex flex-col px-6 py-3">
          <TicketTitle
            title={title}
            description={description}
            isNotificationRead={read}
          />
          <TicketNotification ticketId={ticketId} />
          <span className="flex justify-start mt-1 text-sm">
            Received {formatRelativeTime(timestamp)}
          </span>
        </div>
      </Button>
    </div>
  );
};
export { NotificationItem };
