import type { KeyboardEvent } from "react";

import type {
  DropdownMultiSelectProps,
  DropdownMultiSelectState,
} from "./DropdownMultiSelect.types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Transition } from "@headlessui/react";

import { BodyText, Icon } from "@urbint/silica";
import classNames from "classnames";
import { useAriaId, useDisposables, useWindowEventListener } from "@/hooks";
import { DropdownSelectOption } from "../DropdownSelectOption";
import { filterDuplicatesByLabel } from "../Select.utils";
import {
  close,
  updateStateForNewSearchLetter,
} from "./DropdownMultiSelect.utils";

const DropdownMultiSelect = <T,>({
  options,
  value = [],
  renderLabel = (opts) => opts.map((opt) => opt.label).join(", "),
  onChange,
  disabled,
  placeholder,
  dataTestId,
  dropdownLabel,
  theme,
}: DropdownMultiSelectProps<T>) => {
  const visibleOptions = filterDuplicatesByLabel(options);
  const optionsListRef = useRef<HTMLUListElement>(null);
  const d = useDisposables();
  const [state, setState] = useState<DropdownMultiSelectState>({
    activeIdx: undefined,
    isOpen: false,
    searchTerm: "",
    searchTimeout: 0,
  });
  const ariaId = useAriaId();
  const isEmpty = value?.length === 0;

  const valueSet = useMemo(() => new Set(value), [value]);

  const label = useMemo(() => {
    const selected = (options || []).filter((opt) => valueSet.has(opt.value));
    return renderLabel(selected);
  }, [renderLabel, valueSet, options]);

  const onOpenButtonClick = useCallback(() => {
    setState((state) => {
      if (!state.isOpen) {
        const activeIdx = options?.length ? 0 : undefined;
        return { ...state, isOpen: true, activeIdx };
      }
      return state;
    });
  }, [setState, options?.length]);

  const toggleValue = useCallback(
    (toggle: T) => {
      if (options && state.activeIdx !== undefined) {
        const idx = value.indexOf(toggle);
        const result = [...value];
        if (idx === -1) {
          result.push(toggle);
        } else {
          result.splice(idx, 1);
        }
        onChange(result);
      }
    },
    [onChange, state.activeIdx, options, value]
  );

  const keyListener = useCallback(
    (e: KeyboardEvent<HTMLUListElement>) => {
      if (!(optionsListRef.current?.contains(e.target as Node) || false))
        return;
      if (!state.isOpen) return;

      switch (e.key) {
        case "ArrowUp":
          setState((state) => ({
            ...state,
            activeIdx:
              state.activeIdx && state.activeIdx > 1 ? state.activeIdx - 1 : 0,
          }));
          break;
        case "ArrowDown":
          setState((state) => {
            let newIdx;
            if (!options) {
              newIdx = undefined;
            } else if (state.activeIdx === undefined) {
              newIdx = 0;
            } else {
              newIdx = Math.min(options.length - 1, state.activeIdx + 1);
            }
            return { ...state, activeIdx: newIdx };
          });
          break;
        case " ":
          if (options && state.activeIdx !== undefined) {
            const { value } = options[state.activeIdx!]!;
            toggleValue(value);
          }
          e.preventDefault();
          break;
        case "Enter":
        case "Escape":
          setState((state) => ({ ...state, isOpen: false }));
          e.preventDefault();
          e.stopPropagation();
          break;
        default:
          if (e.key.length === 1) {
            const newState = updateStateForNewSearchLetter(
              state,
              e.key,
              options
            );
            setState(newState);
            if (newState.activeIdx !== undefined) {
              optionsListRef.current?.children[
                newState.activeIdx
              ]?.scrollIntoView({ block: "end" });
            }
          }
      }
    },
    [options, state, setState, toggleValue]
  );

  useEffect(() => {
    if (state.searchTerm) {
      const timeoutId = setTimeout(
        () => setState((state) => ({ ...state, searchTerm: "" })),
        500
      );
      return () => clearTimeout(timeoutId);
    }
  }, [d, setState, state.searchTerm]);

  useWindowEventListener(
    "click",
    (e: MouseEvent) => {
      if (state.isOpen && !optionsListRef.current?.contains(e.target as Node)) {
        // Do this asyncly so that the click can resolve before firing
        // (because we are using a capture listener so this gets called first)
        d.setImmediate(() => setState(close));
      }
    },
    { capture: true },
    [d, state.isOpen, setState]
  );

  return (
    <div className="font-sans relative w-full">
      {dropdownLabel && (
        <p className="font-semibold font-sans text-sm leading-5 mb-1 text-neutral-shade-secondary">
          {dropdownLabel}
        </p>
      )}
      <button
        id={`urbint-multiselect-button-${ariaId}`}
        data-testid={dataTestId}
        type="button"
        disabled={disabled}
        onClick={onOpenButtonClick}
        aria-haspopup="true"
        aria-expanded={state.isOpen}
        aria-controls={
          state.isOpen ? `urbint-multiselect-options-${ariaId}` : undefined
        }
        className={classNames(
          "pl-3 pr-4 text-left flex w-full items-center h-9 border rounded",
          theme?.button,
          {
            "border-borders-disabled": disabled,
            "border-borders": !disabled,
          },
          "truncate flex-nowrap leading-tight"
        )}
      >
        <BodyText
          className={classNames(
            "inline-block overflow-hidden overflow-ellipsis flex-shrink",
            {
              "text-neutrals-disabled": disabled,
              "text-neutrals": !disabled,
            }
          )}
        >
          {isEmpty ? placeholder : label}
        </BodyText>
        <Icon
          className={`ml-auto ${disabled ? "text-neutrals-disabled" : ""}`}
          name="chevron_down"
        />
      </button>
      <Transition
        show={state.isOpen}
        className={`transform-gpu transition duration-100 ease-out absolute origin-top py-2 z-10 bg-white shadow-xl w-full mt-2 top-full rounded font-normal max-h-56 overflow-y-scroll ${theme?.list}`}
        enterFrom="opacity-0 scale-y-0"
        enterTo="opacity-100 scale-y-100"
        leaveFrom="opacity-100 scale-y-100"
        leaveTo="opacity-0 scale-y-0"
        afterEnter={() => {
          optionsListRef.current?.focus({ preventScroll: true });
        }}
      >
        <ul
          id={`urbint-multiselect-options-${ariaId}`}
          ref={optionsListRef}
          onKeyDown={keyListener}
          aria-labelledby={`urbint-multiselect-button-${ariaId}`}
          aria-orientation="vertical"
          aria-activedescendant={
            state.activeIdx === undefined
              ? undefined
              : `urbint-multiselect-option-${ariaId}-${state.activeIdx}`
          }
          role="listbox"
          tabIndex={0}
          className="focus:outline-none"
        >
          {visibleOptions?.map((option, ix) => {
            const selected = valueSet.has(option.value);
            const active = state.activeIdx === ix;
            return (
              <li
                id={`urbint-multiselect-option-${ariaId}-${ix}`}
                key={option.label}
                aria-selected={selected}
                onMouseOver={() => setState({ ...state, activeIdx: ix })}
                onClick={() => toggleValue(option.value)}
                tabIndex={-1}
                role="option"
              >
                <DropdownSelectOption active={active} selected={selected}>
                  {option.label}
                </DropdownSelectOption>
              </li>
            );
          })}
        </ul>
      </Transition>
    </div>
  );
};

export { DropdownMultiSelect };
