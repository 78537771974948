import type {
  AnswerSerializer,
  AnswerSetSerializer,
} from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";
import { ResponseFormAnswer } from "./ResponseFormAnswer";

class ResponseFormAnswerSet extends UrbintModel<AnswerSetSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get answers() {
    const { answers } = this.data;
    if (answers) {
      return this.castArray<ResponseFormAnswer>(
        answers.map((a: AnswerSerializer) => new ResponseFormAnswer(a))
      );
    }
    return [];
  }

  get answersAsValueDict() {
    return Object.fromEntries(
      this.answers.map((a) => [a.questionId, a.answerValue])
    );
  }

  get taskId() {
    return this.castNumber(this.data.task);
  }

  get submitted(): Date | undefined {
    return (this.data.submitted && new Date(this.data.submitted)) || undefined;
  }

  get responseFormId() {
    return this.castNumber(this.data.response_form);
  }

  compare(instance: ResponseFormAnswerSet): number {
    return this.id - instance.id;
  }

  serialize(): AnswerSetSerializer {
    return this.data;
  }
}

export { ResponseFormAnswerSet };
