import type { FormEvent } from "react";
import type { ServiceCenterModalProps } from "./ServiceCenterModal.types";
import type { dialogFooter } from "@/components/Dialog";
import { useEffect, useState } from "react";
import { ComponentLabel, Subheading } from "@urbint/silica";
import { useToasts } from "@/components/Toasts";
import { Dialog } from "@/components/Dialog";
import { DropdownMultiSelect } from "@/components/Select";
import { useServiceCenters, useUpdateServiceCenter } from "@/api";
import {
  serviceCenterIds,
  serviceCenterOptions,
} from "./ServiceCenterModal.utils";

const ServiceCenterModal = ({
  isOpen,
  onClose,
  ticket,
}: ServiceCenterModalProps) => {
  const [showError, setShowError] = useState(false);
  const [selectedServiceCenters, setSelectedServiceCenters] = useState<
    number[]
  >(
    serviceCenterIds(ticket?.serviceCenters ?? []).filter(
      (id): id is number => id !== undefined
    )
  );

  const { addToast } = useToasts();
  const { data: allServiceCentersOptions = [] } = useServiceCenters();
  const { mutateAsync, isLoading } = useUpdateServiceCenter();

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();

    const defaultErrorMessage = `Error updating service center(s) for ticket #${ticket?.number}`;

    if (!ticket?.id) {
      addToast(defaultErrorMessage, "error");
      return;
    }

    mutateAsync(
      {
        ticketId: ticket.id,
        serviceCenters: allServiceCentersOptions.filter((serviceCenter) =>
          selectedServiceCenters.includes(serviceCenter.id)
        ),
      },
      {
        onSuccess: () => {
          addToast(
            `Successfully updated service center/s for ticket #${ticket?.number}`,
            "success"
          );
          onClose();
        },
        onError: (error) => {
          if (error instanceof Error) {
            addToast(`${defaultErrorMessage}: ${error.message}`, "error");
          } else {
            addToast(defaultErrorMessage, "error");
          }
        },
      }
    );
  };

  const handleChange = (newServiceCenters: number[]) => {
    if (newServiceCenters.length === 0) {
      setSelectedServiceCenters([...newServiceCenters]);
      setShowError(true);
    } else {
      setSelectedServiceCenters([...newServiceCenters]);

      if (showError) {
        setShowError(false);
      }
    }
  };

  const handleOnClose = () => {
    setSelectedServiceCenters(
      ticket?.serviceCenters
        ? serviceCenterIds(ticket.serviceCenters).map((id) => id as number)
        : []
    );
    onClose();
  };

  const modalFooter: dialogFooter = {
    toRender: "Confirm",
    onClose: handleOnClose,
    onClick: handleSubmit,
    submitButtonDisabled: showError,
    submitButtonIsLoading: isLoading,
    submitButtonTestId: "submit-selected-service-center",
  };

  const ticketNumber = ticket?.number;
  const sectionHeaderTextValue = `Update Service Center for Ticket #${ticketNumber}?`;

  const errorBorder = showError
    ? "ring-system-error-30 ring-opacity-100 border-system-error-30"
    : "";

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleOnClose}
      variant="warning"
      header=""
      footer={modalFooter}
    >
      <Subheading>{sectionHeaderTextValue}</Subheading>
      <form onSubmit={handleSubmit} className="mt-2">
        <label
          htmlFor="service-center-select"
          className="self-center text-sm text-neutral-shade-secondary"
        >
          <ComponentLabel>Change service center/s...</ComponentLabel>
        </label>
        <DropdownMultiSelect
          placeholder="None"
          value={selectedServiceCenters}
          options={serviceCenterOptions(allServiceCentersOptions)}
          onChange={handleChange}
          theme={{
            button: errorBorder,
            list: "relative max-h-40 overflow-y-scroll",
          }}
        />
      </form>
    </Dialog>
  );
};

export { ServiceCenterModal };
