import type { Notification } from "@/models";
import { IonContent, IonPage, IonRouterOutlet } from "@ionic/react";
import { Suspense } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { MapPage } from "@/pages/MapPage";
import { NavBar } from "@/components/NavBar";
import {
  useReportUserLocationUpdatesToFleet,
  useDamagePreventionAuth,
} from "@/hooks";
import { OfflineBanner } from "@/components/OfflineBanner";
import {
  EventsPage,
  ReportsPage,
  SettingsPage,
  TicketPage,
  TicketHistoryPage,
  TicketPrintPage,
} from "./pages/pagesExporter";
import { Loading } from "./common/Loading";

const AppDesktop = () => {
  // Initialize the user fleet tracking/update
  useReportUserLocationUpdatesToFleet();

  const location = useLocation();
  const withNavigation = !location?.pathname.includes("/ticketPrint");
  const { currentUser } = useDamagePreventionAuth();

  return (
    <IonPage>
      {withNavigation && (
        <>
          <NavBar />
          <OfflineBanner />
        </>
      )}

      <IonContent>
        <Suspense fallback={<Loading />}>
          <IonRouterOutlet>
            <Route path="/" exact>
              {currentUser.canAccessTickets ? (
                <MapPage />
              ) : currentUser.canAccessNonTicketsEvents ? (
                <EventsPage />
              ) : null}
            </Route>
            <Route path="/events" exact>
              {currentUser.canAccessNonTicketsEvents ? (
                <EventsPage />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route exact path="/notifications">
              <Redirect to="/" />
            </Route>
            <Route path="/reports" exact component={ReportsPage} />
            <Route path="/settings" exact component={SettingsPage} />
            <Route
              component={TicketHistoryPage}
              exact
              path="/ticket-history/:ticketId/history/:ticketVersionId?"
            />
            <Route path="/ticket/:ticketId" exact>
              {currentUser.canAccessTickets ? (
                <TicketPage />
              ) : (
                <Redirect to="/tickets" />
              )}
            </Route>
            <Route path="/ticketPrint/:ticketId" component={TicketPrintPage} />
          </IonRouterOutlet>
        </Suspense>
      </IonContent>
    </IonPage>
  );
};

export { AppDesktop };
