import type { PanelHeaderProps } from "./PanelHeader.types";
import { CaptionText, Subheading } from "@urbint/silica";
import cx from "classnames";
import { Button } from "@/common/Button";
import { useDeleteNotifications } from "@/api";
import { useToasts } from "@/components/Toasts";
import { useResponsive } from "@/hooks";

const PanelHeader = ({
  unreadNotifications,
  notificationsCount,
}: PanelHeaderProps) => {
  const { addToast } = useToasts();
  const { isMd } = useResponsive();

  const { mutate: deleteNotifications } = useDeleteNotifications(addToast);

  return (
    <div
      className={cx(
        "w-full p-4 border-b border-gray-40 flex justify-between items-center",
        { "bg-white": isMd },
        { "bg-actions-primary-active": !isMd }
      )}
    >
      <Subheading className={cx({ "text-white": !isMd })}>
        {unreadNotifications
          ? `Notifications (${unreadNotifications})`
          : "Notifications"}
      </Subheading>

      <Button
        onClick={() => {
          deleteNotifications();
        }}
        data-testid="btn-clear-notifications"
        disabled={!notificationsCount}
      >
        <CaptionText className={cx({ "text-white": !isMd })}>
          Clear notifications
        </CaptionText>
      </Button>
    </div>
  );
};

export { PanelHeader };
