import type { DetailProps } from "../DetailsSectionSplitContent/Detail";
import type { DigLocationDetailsSectionProps } from "./DigLocationDetailsSection.types";
import { startCase } from "@/format";
import { ServiceCenter } from "@/components/ServiceCenter";
import { DetailsSection } from "../DetailsSection";
import { DetailsSectionSplitContent } from "../DetailsSectionSplitContent";

const DigLocationDetailsSection = ({
  ticket,
}: DigLocationDetailsSectionProps) => {
  const { latestVersion } = ticket || {};
  const streetName = startCase(latestVersion?.excavationStreetName);
  const streetNum = latestVersion?.excavationStreetNumber;
  const address = [streetNum, streetName].filter(Boolean).join(" ");

  const leftFields: DetailProps[] = [
    {
      label: "Service Center",
      value: ticket ? <ServiceCenter ticket={ticket} /> : "",
    },
    {
      label: "County",
      value: startCase(latestVersion?.excavationCounty),
    },
    {
      label: "Cross Street 1",
      value: startCase(latestVersion?.crossStreet1),
    },
    {
      label: "Cross Street 2",
      value: startCase(latestVersion?.crossStreet2),
    },
  ];
  const rightFields: DetailProps[] = [
    { label: "Address", value: address, layout: "vertical" },
  ];
  return (
    <DetailsSection title="Dig Location">
      <DetailsSectionSplitContent left={leftFields} right={rightFields} />
    </DetailsSection>
  );
};

export { DigLocationDetailsSection };
