import type { TaskResponseFormQuestionHeaderProps } from "./TaskResponseFormQuestionHeader.types";
import { ComponentLabel } from "@urbint/silica";

const TaskResponseFormQuestionHeader = ({
  children,
  className = "",
}: TaskResponseFormQuestionHeaderProps) => (
  <ComponentLabel className={`mb-1 ${className} `}>{children}</ComponentLabel>
);

export { TaskResponseFormQuestionHeader };
