import type { TicketNotificationProps } from "./TicketNotification.types";
import { useTicket } from "@/api";
import { TicketCard } from "@/components/TicketCard";
import { Spinner } from "@/common/Spinner";

const TicketNotification = ({ ticketId }: TicketNotificationProps) => {
  const { isFetching, data: ticket } = useTicket(ticketId);

  return (
    <>
      {ticket && !isFetching ? (
        <TicketCard
          as="div"
          ticket={ticket}
          className="w-full"
          containerClassName="border border-gray-40"
          isClickable={false}
        />
      ) : (
        <div className="flex">
          <Spinner />
        </div>
      )}
    </>
  );
};

export { TicketNotification };
