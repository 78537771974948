import type {
  DamageThreatInsightFeedbackFormProps,
  FormData,
} from "./DamageThreatInsightFeedbackForm.types";
import { Controller, useForm } from "react-hook-form";
import { useSwiper } from "swiper/react";
import { DropdownSelect } from "@/components/Select";
import { Button } from "@/common/Button";
import { AutoGrowingTextArea } from "@/components/AutoGrowingTextArea";
import { useToasts } from "@/components/Toasts";
import { useAddDamageThreatFeedback } from "@/api";
import {
  FORM_TITLE,
  OTHER_OPTION,
  TEXT_AREA_LABEL,
  DROPDOWN_FIELD_NAME,
  TEXTAREA_FIELD_NAME,
} from "./DamageThreatInsightFeedbackForm.constants";
import { getDropdownSelectOptions } from "./DamageThreatInsightFeedbackForm.utils";

const DamageThreatInsightFeedbackForm = ({
  threatData,
  ticketId,
}: DamageThreatInsightFeedbackFormProps) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { isDirty, isValid, isSubmitting },
    reset: formReset,
  } = useForm<FormData>({
    mode: "onChange",
  });
  const selectedOption = watch(DROPDOWN_FIELD_NAME);
  const swiper = useSwiper();
  const { addToast } = useToasts();
  const { mutateAsync } = useAddDamageThreatFeedback({
    ticketId,
    insightId: threatData.id,
  });

  const onSubmit = async (formData: FormData) => {
    try {
      await mutateAsync({
        ticketId,
        insightId: threatData.id,
        feedback: formData.textarea ? formData.textarea : formData.dropdown,
      });

      addToast(`Thank you for your feedback`, "success");
      formReset();
      swiper.updateAutoHeight(100);
    } catch (error) {
      if (error instanceof Error) {
        addToast(`Error submitting feedback: ${error.message}`, "error");
      } else {
        addToast("Error submitting feedback", "error");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name={DROPDOWN_FIELD_NAME}
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <DropdownSelect
            highlightSelectedOption
            label={FORM_TITLE}
            placeholder="Please select an option"
            className="max-w-full sm:max-w-[75%]"
            options={getDropdownSelectOptions(threatData.insight)}
            onChange={(value) => {
              field.onChange(value);
              setTimeout(() => swiper.updateAutoHeight(200), 10);
            }}
            value={field.value}
            testId="dropdown-select-feedback-form"
          />
        )}
      />
      {selectedOption === OTHER_OPTION.value && (
        <div className="mt-4">
          <Controller
            name={TEXTAREA_FIELD_NAME}
            control={control}
            rules={{ required: selectedOption === OTHER_OPTION.value }}
            render={({ field }) => (
              <AutoGrowingTextArea
                maxCharacters={255}
                label={TEXT_AREA_LABEL}
                className="max-w-full sm:max-w-[90%]"
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                  setTimeout(() => swiper.updateAutoHeight(200), 10);
                }}
                testId="text-area-feedback-form"
              />
            )}
          />
        </div>
      )}
      {isDirty && (
        <Button
          className="mt-4"
          type="submit"
          variant="primary"
          disabled={!isValid}
          loading={isSubmitting}
          testId="submit-feedback-form"
        >
          Submit
        </Button>
      )}
    </form>
  );
};

export { DamageThreatInsightFeedbackForm };
