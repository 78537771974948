import type { NotificationsProps } from "./Notifications.types";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { MenuPanel } from "@/components/MenuPanel";
import { NotificationIcon } from "@/components/NotificationIcon";
import { NotificationsPanel } from "@/components/NotificationsPanel";
import { usePlayAudio } from "@/hooks";
import { Button } from "@/common/Button";
import { useToasts } from "@/components/Toasts";
import { useNotifications } from "@/api";
import {
  NOTIFICATION_ANIMATION_DURATION,
  NOTIFICATION_SOUND,
} from "./Notifications.constants";

const Notifications = ({ isMobile }: NotificationsProps) => {
  const { addToast } = useToasts();
  const { playSound } = usePlayAudio(NOTIFICATION_SOUND);
  const { data: notifications, isLoading, isSuccess } = useNotifications();
  const [showNotificationsMenu, setShowNotificationsMenu] = useState(false);
  const previousUnreadCount = useRef(0);
  const initialLoadComplete = useRef(false);
  const [showNotificationAnimation, setShowNotificationAnimation] =
    useState(false);

  const numberOfUnreadNotifications = useMemo(
    () => notifications?.pages[0]?.totalUnread || 0,
    [notifications, notifications?.pages[0]?.totalUnread]
  );

  const triggerSubtleNotificationAnimation = useCallback(() => {
    setShowNotificationAnimation(true);

    const timer = setTimeout(() => {
      setShowNotificationAnimation(false);
    }, NOTIFICATION_ANIMATION_DURATION);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isLoading) return;

    // Handle initial notification after login
    if (isSuccess && !initialLoadComplete.current) {
      initialLoadComplete.current = true;
      previousUnreadCount.current = numberOfUnreadNotifications;

      if (numberOfUnreadNotifications > 0) {
        triggerSubtleNotificationAnimation();
      }
      return;
    }

    // Handle subsequent notifications
    if (
      initialLoadComplete.current &&
      numberOfUnreadNotifications > previousUnreadCount.current
    ) {
      playSound();
      addToast(
        `You have ${numberOfUnreadNotifications} new notification(s)!`,
        "info"
      );
      triggerSubtleNotificationAnimation();
    }

    previousUnreadCount.current = numberOfUnreadNotifications;
  }, [
    notifications,
    isLoading,
    isSuccess,
    numberOfUnreadNotifications,
    addToast,
  ]);

  if (isMobile) {
    return (
      <NotificationIcon
        isMobile={isMobile}
        showNotificationAnimation={showNotificationAnimation}
      />
    );
  }

  return (
    <MenuPanel
      arrow
      onClickOutside={() => setShowNotificationsMenu(false)}
      placement="bottom-end"
      show={showNotificationsMenu}
      renderButton={() => (
        <div slot="end" className="flex flex-col justify-center">
          <Button
            data-testid="urbint-navbar-notification-menu"
            onClick={() => setShowNotificationsMenu(!showNotificationsMenu)}
          >
            <NotificationIcon
              isMobile={isMobile}
              showNotificationAnimation={showNotificationAnimation}
            />
          </Button>
        </div>
      )}
    >
      <NotificationsPanel />
    </MenuPanel>
  );
};

export { Notifications };
