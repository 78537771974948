import type { NotificationIconProps } from "@/components/NotificationIcon/NotificationIcon.types";
import { Icon } from "@urbint/silica";
import cx from "classnames";
import { useNotifications } from "@/api";

const NotificationIcon = ({
  isMobile,
  showNotificationAnimation = false,
}: NotificationIconProps) => {
  const { data: notifications } = useNotifications();
  const hasUnreadNotifications = notifications?.pages[0]?.totalUnread;

  const notificationCircleClasses =
    "inline-flex w-full h-full rounded-full bg-brand-50";
  return (
    <div className="flex">
      <Icon
        name="notification_outline"
        className={cx(
          "text-2xl",
          isMobile && !hasUnreadNotifications
            ? "text-gray-400"
            : "text-brand-50"
        )}
      />
      {hasUnreadNotifications ? (
        <div className="relative flex w-1.5 h-1.5 self-center">
          <div
            className={cx(
              "absolute rounded-full opacity-75",
              notificationCircleClasses,
              showNotificationAnimation && "animate-ping"
            )}
          />
          <div className={cx(notificationCircleClasses)} />
        </div>
      ) : null}
    </div>
  );
};
export { NotificationIcon };
