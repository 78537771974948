import type { Question, Task } from "@/models";
import type { FormEvent } from "react";
import type { MemberCodeWithPrCode } from "@/pages/TicketPage/TaskDetail/TaskDetail.types";
import { useEffect, useCallback, useMemo, useRef, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { StatusCodes } from "http-status-codes";
import { QuestionAnswerType } from "@/models";
import { useDamagePreventionAuth, useOnlineStatus } from "@/hooks";
import {
  useAnswerResponseForm,
  useAnswerSet,
  useDynamicOptions,
  useResponseForm,
  useTaskType,
  saveAnswerResponseForm,
} from "@/api";
import { useToasts } from "@/components/Toasts";
import { formatCalendarDate, formatClockTime } from "@/format";
import { indexByKV, isFormValid, type FormValues } from "@/utils";
import { useResponseFormHandler } from "@/hooks/useResponseFormHandler";
import { useTasksStepsContext } from "@/pages/TicketPage/TicketPageDetails/Header/TopHeader/TasksSteps.context";
import {
  RESPONSE_FORM_PREFIX,
  RESPONSE_FORM_SUBMITTED_SUFFIX,
} from "./useResponseFormLogic.constants";

const DATADOG_TASK_RESPONSE_FORM_ERROR = "TASK_RESPONSE_FORM_ERROR";

const useResponseFormLogic = (task: Task, ticketNumber?: string) => {
  const { currentUser } = useDamagePreventionAuth();
  const { updateTaskStep, removeTaskStep } = useTasksStepsContext();
  const { id: taskId, ticketId, taskTypeId } = task;

  // Note, the below formValuesState and formValuesRef is a bit confusing.
  // The reason that we have both is because we need to be able to execute onBlur / onChange handlers
  // that atomically both update the state and post a request to the autosave endpoint. As such, we need
  // the post requests to immediately have the latest values of the form, rather than wait for a re-render
  // rebind of event handlers. We then create a hybrid `setFormValues` function that does both an update
  // of the state and the ref.
  const [isUserEditing, setIsUserEditing] = useState(false);
  const formBackup = useRef<FormValues>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isOnline = useOnlineStatus();
  const [hasAttemptedFormSubmission, setHasAttemptedFormSubmission] =
    useState(false);

  // --- Offline Support Local Keys --- //
  const localStorageFormDataKey = `${RESPONSE_FORM_PREFIX}${ticketId}-${taskId}`;
  const localStorageFormWasSubmitted = `${RESPONSE_FORM_PREFIX}${ticketId}-${taskId}${RESPONSE_FORM_SUBMITTED_SUFFIX}`;

  // --- Offline Support Local Values--- //
  const offlineLocalFormData = localStorage.getItem(localStorageFormDataKey);
  const offlineLocalFormWasSubmitted = localStorage.getItem(
    localStorageFormWasSubmitted
  );

  // --- API Calls --- //
  const { data: taskType } = useTaskType(taskTypeId, {
    enabled: task !== undefined,
  });

  const { data: currentAnswerSet, isInitialLoading: answerSetInitialLoading } =
    useAnswerSet(taskId, { enabled: task !== undefined });

  const { data: responseForm } = useResponseForm(
    // TODO: Fix no-non-null-asserted-optional-chain
    currentAnswerSet?.responseFormId!,
    {
      enabled: !!currentAnswerSet?.responseFormId,
    }
  );

  const {
    data: dynamicOptions = {},
    isInitialLoading: dynamicQuestionsLoading,
  } = useDynamicOptions(
    { taskId, responseFormId: Number(responseForm?.id) },
    { enabled: !!responseForm && !!Number(responseForm?.id) }
  );

  const {
    formValuesRef,
    setFormValues,
    formValues,
    setIsQuestionDirty,
    setQuestionValue,
    getIsQuestionDirty,
    getOptionsForQuestion,
    getQuestionValue,
    setAllQuestionsAsDirty,
    isReadyForSubmission,
    isDynamicForm,
    getAnsweredQuestionsToSubmit,
  } = useResponseFormHandler(responseForm, dynamicOptions);

  const startUserEditing = useCallback(() => {
    formBackup.current = formValuesRef.current;
    setIsUserEditing(true);
  }, [setIsUserEditing, formBackup, formValuesRef]);

  const cancelUserEditing = useCallback(() => {
    // --- Offline Support remove local data  --- //
    localStorage.removeItem(localStorageFormDataKey);

    setFormValues(formBackup.current);
    setIsUserEditing(false);
  }, [setIsUserEditing, setFormValues]);

  const [isAutosaveError, setIsAutosaveError] = useState(false);

  const canEditResponseForm = useMemo(
    () =>
      (currentUser.canSubmitResponseForm && !currentAnswerSet?.submitted) ||
      (currentUser.canEditResponseForm && Boolean(currentAnswerSet?.submitted)),
    [
      currentAnswerSet?.submitted,
      currentUser.canEditResponseForm,
      currentUser.canSubmitResponseForm,
    ]
  );

  const [isAutosaving, setIsAutosaving] = useState<boolean>(false);
  const [autosavedAt, setAutosavedAt] = useState<Date | undefined>(undefined);

  const { addToast } = useToasts();
  const { mutateAsync, isPaused: answerResponseFormIsPaused } =
    useAnswerResponseForm({ taskId, ticketId });

  const postAnswerSet = async (autoSave = false) => {
    const answeredQuestions = getAnsweredQuestionsToSubmit();

    try {
      setIsAutosaveError(false);
      setIsAutosaving(autoSave);
      if (!autoSave) {
        setIsSubmitting(true);
        setHasAttemptedFormSubmission(true);
        if (
          !responseForm ||
          !isFormValid(
            responseForm?.questions,
            formValuesRef.current,
            responseForm?.isFormDynamic
          )
        ) {
          setAllQuestionsAsDirty();
          return;
        }
      }

      // --- Offline Support --- //
      // Auto save form data into local storage with the new formValues
      if (autoSave && !isOnline) {
        localStorage.setItem(
          localStorageFormDataKey,
          JSON.stringify(formValues)
        );

        updateTaskStep(taskId, "inprogress");
      }

      const { member_codes, third_party } = (await saveAnswerResponseForm(
        mutateAsync,
        {
          task,
          responseForm,
          questions: answeredQuestions,
          autoSave,
          isOnline,
        }
      )) as any;

      if (autoSave) {
        setAutosavedAt(new Date());
      } else {
        setIsUserEditing(false);
        setAutosavedAt(undefined);
        const msg =
          currentAnswerSet?.submitted === undefined
            ? "Task completed."
            : "Response form updated.";
        addToast(msg, "success");
        if (member_codes.length > 0 && third_party) {
          addToast(
            `Forwarded a ticket to ${third_party} for ${member_codes.toString()}.`,
            "success"
          );
        }
      }

      // --- Offline Support remove local data --- //
      localStorage.removeItem(localStorageFormDataKey);
      localStorage.removeItem(localStorageFormWasSubmitted);
      removeTaskStep(taskId);
    } catch (e) {
      datadogRum.addError(e, {
        errorIdentifier: DATADOG_TASK_RESPONSE_FORM_ERROR,
      });
      if (autoSave) setIsAutosaveError(true);
      if (e instanceof Error) {
        if (e.message.includes(String(StatusCodes.INTERNAL_SERVER_ERROR))) {
          addToast(
            `The ${taskType?.name} submission failed for #${ticketNumber}`,
            "error"
          );
          return;
        }
        addToast(`Error ${autoSave ? "saving" : "submitting"} task`, "error");
      } else {
        addToast("Error saving task", "error");
      }
      localStorage.removeItem(localStorageFormWasSubmitted);
    } finally {
      setIsAutosaving(false);
      setIsSubmitting(false);
    }
  };

  const submitForm = useCallback(
    (e?: FormEvent) => {
      e?.preventDefault();
      postAnswerSet(false);

      // --- Offline Support --- //
      if (!isOnline && isReadyForSubmission) {
        isUserEditing && setIsUserEditing(false);

        localStorage.setItem(
          localStorageFormWasSubmitted,
          JSON.stringify(true)
        );

        updateTaskStep(taskId, "done");

        addToast(
          `The form will be ${
            isUserEditing ? "updated" : "submitted"
          } when we come back online`,
          "info"
        );
      }
    },
    [postAnswerSet, isOnline]
  );

  const autosaveForm = useCallback(() => {
    if (!currentAnswerSet?.submitted) postAnswerSet(true);
  }, [postAnswerSet, currentAnswerSet?.submitted]);

  const getStringifiedAnswerForQuestion = useCallback(
    (question: Question) => {
      const value = formValues[question.id];
      const options = getOptionsForQuestion(question);
      if (!value) return undefined;
      if (!options || !options.length) {
        if (question.answerType === QuestionAnswerType.DATE_SELECTOR) {
          return formatCalendarDate(new Date(value as string));
        }
        if (question.answerType === QuestionAnswerType.DATE_TIME_SELECTOR) {
          const date = new Date(value as string);
          return `${formatCalendarDate(date)}, ${formatClockTime(date)}`;
        }
        return value as string;
      }
      const optionToLabel = indexByKV(options, (x) => [x.value, x.label]);
      if (typeof value === "string") {
        return optionToLabel.get(value) || "";
      }
      return (value || [])
        .map((x) => optionToLabel.get(x) || "")
        .filter((x) => x !== "")
        .join(", ");
    },
    [formValues, getOptionsForQuestion]
  );

  const getStringifiedResponsesForMultiPRQuestion = useCallback(
    (question: Question, dynamicOptions) => {
      const value = formValues[question.id];
      if (!value) return undefined;
      const parsedValue = JSON.parse(value as string);
      const { memberCodesWithPrCodes } = dynamicOptions[question.id];

      return memberCodesWithPrCodes.map((memberCode: MemberCodeWithPrCode) => {
        const prCodeId = parsedValue[memberCode.id];
        const prCodeLabel = memberCode.prCodes.find(
          (prCode: { id: number; text: string }) =>
            prCode.id === parseInt(prCodeId, 10)
        )?.text;

        return {
          memberCode: memberCode.label,
          prCode: prCodeLabel,
        };
      });
    },
    [formValues]
  );

  useEffect(() => {
    if (currentAnswerSet) {
      setFormValues(currentAnswerSet?.answersAsValueDict || {}, true);
    }
  }, [currentAnswerSet]);

  // --- Offline Support --- //
  // Component mount
  useEffect(() => {
    // We have local data  of the task saved to set in form
    if (offlineLocalFormData) {
      setFormValues(JSON.parse(offlineLocalFormData));
    }
    // Form was submitted  while the user was offline
    if (offlineLocalFormWasSubmitted) {
      setIsSubmitting(true);
    }
  }, []);

  //
  // --- Return
  //
  return useMemo(() => {
    const defaultIsEditing =
      !currentAnswerSet?.submitted && canEditResponseForm;
    return {
      canEditResponseForm,
      isReadyForSubmission,
      isEditing: isUserEditing || defaultIsEditing,
      isSubmitting,
      defaultIsEditing,
      isUserEditing,
      startUserEditing,
      cancelUserEditing,
      isAutosaving,
      isAutosaveError,
      autosavedAt,
      autosaveForm,
      setIsUserEditing,
      getIsQuestionDirty,
      setIsQuestionDirty,
      setQuestionValue,
      getQuestionValue,
      getOptionsForQuestion,
      getStringifiedAnswerForQuestion,
      getStringifiedResponsesForMultiPRQuestion,
      hasAttemptedFormSubmission,
      submitForm,
      questions: responseForm?.questions.sort((a, b) => a.compare(b)),
      isSubmitted: currentAnswerSet?.submitted !== undefined,
      submittedAt: currentAnswerSet?.submitted,
      isInitialLoading: dynamicQuestionsLoading || answerSetInitialLoading,
      dynamicOptions,
      answerResponseFormIsPaused,

      // --- Offline Support --- //
      isSubmittedOffline: Boolean(offlineLocalFormWasSubmitted),

      // --- Dynamic Form --- //
      isDynamicForm,
    };
  }, [
    autosaveForm,
    autosavedAt,
    getIsQuestionDirty,
    setIsQuestionDirty,
    getOptionsForQuestion,
    getQuestionValue,
    setQuestionValue,
    getStringifiedAnswerForQuestion,
    getStringifiedResponsesForMultiPRQuestion,
    hasAttemptedFormSubmission,
    isAutosaving,
    isAutosaveError,
    isSubmitting,
    isReadyForSubmission,
    dynamicQuestionsLoading,
    answerSetInitialLoading,
    canEditResponseForm,
    currentAnswerSet?.submitted,
    responseForm?.questions,
    responseForm?.getResponseOptions,
    isUserEditing,
    startUserEditing,
    cancelUserEditing,
    submitForm,
    dynamicOptions,
    answerResponseFormIsPaused,

    // --- Offline Support --- //
    offlineLocalFormWasSubmitted,

    // --- Dynamic Form --- //
    isDynamicForm,
  ]);
};

export { useResponseFormLogic };
