import { Howl } from "howler";
import { useCallback, useEffect, useRef } from "react";

function usePlayAudio(soundSrc: string, volume = 1) {
  const soundRef = useRef<Howl | null>(null);

  useEffect(() => {
    soundRef.current = new Howl({
      src: [soundSrc],
      volume,
      preload: true,
    });

    return () => {
      soundRef.current?.unload();
    };
  }, [soundSrc]);

  const playSound = useCallback(() => {
    soundRef.current?.play();
  }, []);

  return { playSound, soundRef };
}

export { usePlayAudio };
