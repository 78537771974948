import type { TaskResponseFormQuestionProps } from "./TaskResponseFormQuestion.types";
import { useAnswerSet, useResponseForm } from "@/api";
import { QuestionAnswerType } from "@/models";
import { TaskResponseFormQuestionHeader } from "../TaskResponseFormQuestionHeader";

const TaskResponseFormQuestion = ({
  question,
  testId,
  value,
  task,
}: TaskResponseFormQuestionProps) => {
  if (value === ", " || value === undefined) {
    value = "";
  }

  if (!task || !question) {
    return null;
  }

  const { id: taskId } = task;
  const { data: currentAnswerSet } = useAnswerSet(taskId, {
    enabled: !!task,
  });
  const answers: any[] = currentAnswerSet?.answers || [];

  const multiSelectResponseForms = answers.filter(
    (obj: { data?: { answer_type: string } }) =>
      obj.data?.answer_type === "MULTI_SELECT_PICKLIST"
  );

  const answerContentsMultiSelect = multiSelectResponseForms.map(
    (form) => form.data.answer_content
  );

  const splitValues = (values: string[]): string[] =>
    values.flatMap((value) => value.split(","));

  const answerContents = splitValues(answerContentsMultiSelect);

  const singleSelectResponseForms = answers.filter(
    (item) => item.data.answer_type === "SINGLE_SELECT_PICKLIST"
  );

  const positiveResponseForms = answers.filter(
    (item) => item.data.answer_type === "POSITIVE_RESPONSE_CODE"
  );

  const { data: responseForm } = useResponseForm(
    // TODO: Fix no-non-null-asserted-optional-chain
    currentAnswerSet?.responseFormId!,
    {
      enabled: !!currentAnswerSet?.responseFormId,
    }
  );

  let deletedOptions: any[] = [];

  switch (question.answerType) {
    case QuestionAnswerType.MULTI_SELECT_PICKLIST:
      if (question.required === true) {
        deletedOptions =
          responseForm?.getResponseOptions(question?.id, true) || [];

        const multipleDeletedOptionsObject = deletedOptions?.map(
          (option: any) => ({
            id: option?.id?.toString() || "",
            text: option?.text || "",
          })
        );

        const transformedValues = answerContents.map((answer: string) => {
          const matchingOption = multipleDeletedOptionsObject?.find(
            (option) => option.id === answer
          );
          return matchingOption ? matchingOption.text : "";
        });

        const nonEmptyValues = transformedValues.filter(
          (value: string) => value !== ""
        );
        const deletedAnswerString = nonEmptyValues.join(", ");

        if (
          answerContents.length === value.length ||
          answerContents.length > value.length
        ) {
          value = "";
        }
        value = `${deletedAnswerString || ""}${
          deletedAnswerString && value ? ", " : ""
        }${value || ""}`;
      } else {
        deletedOptions =
          responseForm?.getResponseOptions(question?.id, true) || [];

        const multipleDeletedOptionsObject = deletedOptions?.map(
          (option: any) => ({
            id: option?.id?.toString() || "",
            text: option?.text || "",
          })
        );

        const transformedValues = answerContents.map((answer: string) => {
          const matchingOption = multipleDeletedOptionsObject?.find(
            (option) => option.id === answer
          );
          return matchingOption ? matchingOption.text : "";
        });

        const nonEmptyValues = transformedValues.filter(
          (value: string) => value !== ""
        );
        const deletedAnswerString = nonEmptyValues.join(", ");

        if (
          answerContents.length === value.length ||
          answerContents.length > value.length
        ) {
          value = "";
        }
        value = `${deletedAnswerString || ""}${
          deletedAnswerString && value ? ", " : ""
        }${value || ""}`;
      }
      break;

    case QuestionAnswerType.SINGLE_SELECT_PICKLIST:
      if (question.required === true) {
        deletedOptions =
          responseForm?.getResponseOptions(question?.id, true) || [];
        const deletedOptionsObject = deletedOptions?.map((option: any) => ({
          id: option?.id?.toString() || "",
          text: option?.text || "",
        }));

        const responseOptions =
          responseForm?.getResponseOptions(question?.id, false) || [];
        let singleSelectData: Array<string> = [];

        if (responseOptions.length > 0) {
          singleSelectData = singleSelectResponseForms
            .filter((item) => item.data.answer_content !== value)
            .map((item) => item.data.answer_content);
        } else {
          singleSelectData = singleSelectResponseForms
            .filter((item) => item.data.answer_content === value)
            .map((item) => item.data.answer_content);
        }

        const singleSelectDeletedOption = deletedOptionsObject?.find(
          (option) => option.id === singleSelectData[0]
        );
        value = singleSelectDeletedOption?.text || value;
      } else {
        const responseOptions =
          responseForm?.getResponseOptions(question?.id, false) || [];
        let filteredSingleSelectData: Array<string> = [];

        if (responseOptions.length > 0) {
          filteredSingleSelectData = singleSelectResponseForms
            .filter((item) => item.data.answer_content !== value)
            .map((item) => item.data.answer_content);
        } else {
          filteredSingleSelectData = singleSelectResponseForms
            .filter((item) => item.data.answer_content === value)
            .map((item) => item.data.answer_content);
        }

        const singleDeletedOptionsNotRequired =
          responseForm?.getResponseOptions(question?.id, true) || [];
        const singleSelectNotRequiredOption: any[] = [];

        filteredSingleSelectData.forEach((id) => {
          const option = singleDeletedOptionsNotRequired?.find(
            (option) => option.id.toString() === id
          );
          if (option) {
            singleSelectNotRequiredOption.push(option);
          }
        });

        value = singleSelectNotRequiredOption[0]?.text || value;
      }
      break;

    case QuestionAnswerType.POSITIVE_RESPONSE_CODE:
      if (question.required === true) {
        deletedOptions =
          responseForm?.getResponseOptions(question?.id, true) || [];
        const deletedOptionsObject = deletedOptions?.map((option: any) => ({
          id: option?.id?.toString() || "",
          text: option?.text || "",
        }));

        const responseOptions =
          responseForm?.getResponseOptions(question?.id, false) || [];
        let positiveResponseSelectData: Array<string> = [];

        if (responseOptions.length > 0) {
          positiveResponseSelectData = positiveResponseForms
            .filter((item) => item.data.answer_content !== value)
            .map((item) => item.data.answer_content);
        } else {
          positiveResponseSelectData = positiveResponseForms
            .filter((item) => item.data.answer_content === value)
            .map((item) => item.data.answer_content);
        }

        const postiveResponseDeletedOption = deletedOptionsObject?.find(
          (option) => option.id === positiveResponseSelectData[0]
        );
        value = postiveResponseDeletedOption?.text || value;
      } else {
        const responseOptions =
          responseForm?.getResponseOptions(question?.id, false) || [];
        let filteredPositiveResponseData: Array<string> = [];

        if (responseOptions.length > 0) {
          filteredPositiveResponseData = positiveResponseForms
            .filter((item) => item.data.answer_content !== value)
            .map((item) => item.data.answer_content);
        } else {
          filteredPositiveResponseData = positiveResponseForms
            .filter((item) => item.data.answer_content === value)
            .map((item) => item.data.answer_content);
        }

        const positiveResponseOptionsNotRequired =
          responseForm?.getResponseOptions(question?.id, true) || [];
        const positiveResponseNotRequiredOption: any[] = [];

        filteredPositiveResponseData.forEach((id) => {
          const option = positiveResponseOptionsNotRequired?.find(
            (option) => option.id.toString() === id
          );
          if (option) {
            positiveResponseNotRequiredOption.push(option);
          }
        });
        value = positiveResponseNotRequiredOption[0]?.text || value;
      }
      break;

    default:
      value;
      break;
  }

  const hasAnswer = value !== undefined;

  return (
    <div>
      <TaskResponseFormQuestionHeader>
        {question.text}
      </TaskResponseFormQuestionHeader>
      <p
        data-testid={testId}
        className={`leading-tight mb-4 ${
          hasAnswer
            ? "text-neutral-shade-primary"
            : "text-neutral-shade-tertiary"
        }`}
      >
        {value || "–"}
      </p>
    </div>
  );
};

export { TaskResponseFormQuestion };
