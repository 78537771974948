import type { Question, ResponseForm } from "@/models";
import type { SelectOption } from "@/components/Select";
import type { QuestionSerializer } from "./damage-prevention";
import { answerValidator, QuestionAnswerType } from "@/models";

type FormValues = { [key: number]: string | string[] };
type QuestionValue = string | string[] | undefined;

const isFormValid = (
  questions: Question[],
  values: FormValues,
  isDynamicForm: boolean
): boolean => {
  if (!isDynamicForm) {
    const requiredQuestions = questions.filter((q) => q.required);
    return requiredQuestions.every((rq) =>
      answerValidator(rq.answerType, values[rq.id])
    );
  }

  // Get all enabled questions
  const getAllEnabledQuestions = questions.filter((q) => !q.isDisable);

  // If we don't have at least one "final" question, it's not valid, so return false
  if (getAllEnabledQuestions.every((question) => !question.final)) {
    return false;
  }

  // Get all enabled && required questions
  const AllEnabledAndRequiredQuestions = getAllEnabledQuestions.filter(
    (q) => q.required
  );

  // Check if all required questions are answered
  return AllEnabledAndRequiredQuestions.every((rq) =>
    answerValidator(rq.answerType, values[rq.id])
  );
};

const sameLabelAndValueOptions = (label: string): SelectOption<string> => ({
  label,
  value: label,
});

const getNextRequiredQuestionId = (
  questions: Question[],
  currentQuestionId: number,
  isFinal?: boolean
): [number] | [] => {
  if (isFinal) {
    return [];
  }

  // Find the current question's index
  const currentIndex = questions.findIndex((q) => q.id === currentQuestionId);

  // If the current question is not found or it's the last in the list
  if (currentIndex === -1 || currentIndex === questions.length - 1) {
    return [];
  }

  // Find the next question with `required` set to true
  const nextRequiredQuestion = questions
    .slice(currentIndex + 1)
    .find((q) => q.required);

  return nextRequiredQuestion ? [nextRequiredQuestion.id] : [];
};

const updateEnabledQuestions = (
  startQuestionId: number,
  responseForm: ResponseForm,
  formValuesRef: React.MutableRefObject<FormValues>,
  isDynamicForm: boolean
) => {
  if (!responseForm || !isDynamicForm) return;
  const questionMap = new Map(responseForm.questions.map((q) => [q.id, q]));
  const enabledQuestions = new Set<number>();
  const queue = [startQuestionId];
  while (queue.length > 0) {
    // Remove first in the queue
    const currentQuestionId = queue.shift();
    if (!currentQuestionId || enabledQuestions.has(currentQuestionId)) {
      // Skip if already processed
      continue;
    }
    const currentQuestion = questionMap.get(currentQuestionId);
    if (!currentQuestion) {
      // Skip if the question doesn't exist in the map
      continue;
    }
    // Mark the question as enabled
    enabledQuestions.add(currentQuestionId);
    // Get the next dependent questions
    const nextQuestions = findNextQuestions(
      currentQuestion,
      responseForm,
      formValuesRef,
      isDynamicForm
    );
    // Add new questions to the queue
    nextQuestions.forEach((nextId) => {
      if (!enabledQuestions.has(nextId)) {
        queue.push(nextId);
      }
    });
  }
  // Apply enabled/disabled state updates
  responseForm.setQuestionsToEnableOrDisable(Array.from(enabledQuestions));
};

const findNextQuestions = (
  question: Question,
  responseForm: ResponseForm,
  formValuesRef: React.MutableRefObject<FormValues>,
  isDynamicForm: boolean
): number[] => {
  if (!responseForm || !isDynamicForm) {
    return [];
  }

  const { getAllResponseOptionsByQuestionId, questions } = responseForm;
  const { answerType, id, final } = question;

  let nextQuestions: number[] = [];

  // Get question answered value
  const selectedOptionIdOrValue = formValuesRef.current[id];

  if (
    answerType === QuestionAnswerType.SINGLE_SELECT_PICKLIST ||
    answerType === QuestionAnswerType.MULTI_SELECT_PICKLIST
  ) {
    if (!selectedOptionIdOrValue) {
      return [];
    }

    const selectedOptionIds = Array.isArray(selectedOptionIdOrValue)
      ? selectedOptionIdOrValue.map(Number)
      : [Number(selectedOptionIdOrValue)];
    const options = getAllResponseOptionsByQuestionId?.[id] || [];
    // Find all matching options and their `nextQuestions`
    nextQuestions = options
      .filter((option) => selectedOptionIds.includes(option.id))
      .flatMap((option) => option.nextQuestions || []);
  }

  // If no next questions are found and we have set a value for the question we should unblock the next required question
  if (nextQuestions.length === 0 && selectedOptionIdOrValue) {
    const nextRequiredQuestion = getNextRequiredQuestionId(
      questions,
      id,
      final
    );
    return nextRequiredQuestion || [];
  }

  return nextQuestions;
};

const isFormDynamic = (questions: Question[] | QuestionSerializer[]) =>
  questions.some((q) => q.final === true);

const getFirstRequiredQuestion = (questions: Question[]) =>
  questions.find((q) => q.required);

export type { FormValues, QuestionValue };
export {
  isFormValid,
  sameLabelAndValueOptions,
  getNextRequiredQuestionId,
  getFirstRequiredQuestion,
  findNextQuestions,
  updateEnabledQuestions,
  isFormDynamic,
};
