import type { ServiceCenterColumnProps } from "./ServiceCenterColumn.types";
import type { FlexServiceCenter, Ticket } from "@/models";
import { useState } from "react";
import { BodyText } from "@urbint/silica";
import { Button } from "@/common/Button";
import { ServiceCenterPopUp } from "@/components/ServiceCenterPopUp";
import { Tooltip } from "@/common/Tooltip";
import { ServiceCenter } from "../ServiceCenter";
import { MAX_SERVICE_CENTERS_VISIBLE } from "./ServiceCenterColumn.constants";

const ShowServiceCenters = ({
  serviceCenters,
}: {
  serviceCenters: FlexServiceCenter[];
}) => (
  <>
    {serviceCenters.map((serviceCenter, index) => (
      <BodyText
        key={serviceCenter.id}
        className={`truncate ${
          serviceCenters.length > 1 ? "max-w-[3.5rem]" : "max-w-[11.5rem]"
        } cursor-pointer`}
      >
        {index !== 0 && ","}
        {serviceCenter.name}
      </BodyText>
    ))}
  </>
);

const ServiceCenterColumn = ({ ticket }: ServiceCenterColumnProps) => {
  const [showServiceCenterPopup, setShowServiceCenterPopup] = useState(false);
  const { serviceCenters = [] } = ticket;

  const renderServiceCenters = () => {
    const visibleServiceCenters = serviceCenters.slice(
      0,
      MAX_SERVICE_CENTERS_VISIBLE
    );

    const serviceCentersNames = serviceCenters.map((sc) => sc.name).join(", ");

    return (
      <div className="flex">
        <Tooltip title={serviceCentersNames}>
          <ShowServiceCenters serviceCenters={visibleServiceCenters} />
        </Tooltip>
        {serviceCenters.length > MAX_SERVICE_CENTERS_VISIBLE && (
          <Button
            className="text-brand-50 underline hover:text-blue-700 focus:outline-none text-xs"
            onClick={() => setShowServiceCenterPopup(true)}
          >
            Show More
          </Button>
        )}
        <ServiceCenter ticket={ticket} disableServiceCenterNames />
      </div>
    );
  };

  return (
    <>
      {renderServiceCenters()}
      <ServiceCenterPopUp
        isVisible={showServiceCenterPopup}
        setVisibility={setShowServiceCenterPopup}
        ticket={ticket}
      />
    </>
  );
};

export { ServiceCenterColumn };
