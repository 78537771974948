import type { AddToastFunction } from "@/components/Toasts";
import { useMutation } from "@tanstack/react-query";
import { UrbintApi } from "@/utils/UrbintApi";
import { handleApiResponse } from "@/api/helpers";
import { invalidateNotificationsQuery } from "../queries";

const api = new UrbintApi();

const clearNotifications = () =>
  api.post("notifications/clear").then(handleApiResponse);

const deleteNotification = (notificationId: number) =>
  api.delete(`notifications`, notificationId);

const useDeleteNotifications = (addToast: AddToastFunction) =>
  useMutation(() => clearNotifications(), {
    onSuccess: () => {
      invalidateNotificationsQuery();
    },
    onError: (error: Error) => {
      addToast("Fail to delete all notifications.", "error");
      console.error("Error deleting notifications:", error);
    },
  });

const useDeleteNotification = (
  notificationId: number,
  addToast: AddToastFunction
) =>
  useMutation(() => deleteNotification(notificationId), {
    onSuccess: () => {
      invalidateNotificationsQuery();
    },
    onError: (error: Error) => {
      addToast("Fail to delete notification.", "error");
      console.error("Error deleting notification id:", notificationId, error);
    },
  });

export { useDeleteNotifications, useDeleteNotification };
