import type { DropdownSelectDefaultButtonProps } from "./DropdownSelectDefaultButton.types";
import { BodyText, Icon } from "@urbint/silica";
import classNames from "classnames";
import { SelectButton } from "./SelectButton";

const DropdownSelectDefaultButton = <T,>({
  testId,
  placeholder,
  selectedOption,
  className,
  disabled,
}: DropdownSelectDefaultButtonProps<T>) => (
  <SelectButton
    className={classNames(
      "border rounded",
      {
        "border-borders-disabled": disabled,
        "border-borders": !disabled,
      },
      className
    )}
    testId={testId}
  >
    <BodyText
      className={classNames("overflow-hidden overflow-ellipsis", {
        "text-neutrals-disabled": disabled,
      })}
    >
      {selectedOption ? selectedOption.label : placeholder}
    </BodyText>
    <Icon
      className={classNames("ml-auto", {
        "text-neutrals-disabled": disabled,
      })}
      name="chevron_down"
    />
  </SelectButton>
);

export { DropdownSelectDefaultButton };
