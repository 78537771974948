import type {
  QuestionSerializer,
  ResponseFormSerializer,
  ResponseOptionSerializer,
} from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";
import { getFirstRequiredQuestion, isFormDynamic } from "@/utils";
import { ResponseOption } from "./ResponseOption";
import { Question, QuestionAnswerType } from "./Question";

class ResponseForm extends UrbintModel<ResponseFormSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get questions() {
    // Check if form is dynamic
    const isDynamic = isFormDynamic(this.data.questions);

    const questions = this.castArray<Question>(
      this.data.questions.map(
        (q: QuestionSerializer) => new Question(q, isDynamic)
      )
    );

    if (isDynamic) {
      // Find the first required question
      const firstQuestion = getFirstRequiredQuestion(questions);

      // If a required question is found, enable it
      if (firstQuestion) {
        firstQuestion.isDisable = false;
      }
    }

    return questions;
  }

  get hasMultiPRQuestion(): boolean {
    return (
      this.questions.find(
        (question) =>
          question.answerType === QuestionAnswerType.MULTI_PR_QUESTION
      ) !== undefined
    );
  }

  getResponseOptions(
    questionId: number,
    includeDeleted = false
  ): ResponseOption[] {
    const allFormResponses = this.data.response_options_by_question_id;

    if (
      allFormResponses === undefined ||
      allFormResponses[questionId] === undefined
    ) {
      return this.castArray<ResponseOption>([]);
    }

    const options = allFormResponses[questionId];

    if (options) {
      // Filter out deleted options if includeDeleted is false
      const filteredOptions = includeDeleted
        ? options
        : options.filter((option) => !option.is_deleted);
      return this.castArray<ResponseOption>(filteredOptions);
    }

    return [];
  }

  get getAllResponseOptionsByQuestionId(): Record<number, ResponseOption[]> {
    const responseOptionsByQuestionId =
      this.data.response_options_by_question_id || {};

    const mappedResponseOptions: Record<number, ResponseOption[]> = {};

    Object.keys(responseOptionsByQuestionId).forEach((questionId) => {
      const options = responseOptionsByQuestionId[Number(questionId)];

      if (options) {
        // Cast to ResponseOption
        mappedResponseOptions[Number(questionId)] = options.map(
          (option: ResponseOptionSerializer) => new ResponseOption(option)
        );
      } else {
        mappedResponseOptions[Number(questionId)] = [];
      }
    });

    return mappedResponseOptions;
  }

  setQuestionsToEnableOrDisable(questionIds: number[]): void {
    // Create a Set for quick lookups
    const questionIdsSet = new Set(questionIds);
    const firstQuestion = getFirstRequiredQuestion(this.questions);

    // Loop through all questions
    this.questions.forEach((question) => {
      // First question is already always enabled
      if (question.id === firstQuestion?.id) {
        return;
      }

      // Only required questions can be enabled/disabled
      if (question.required) {
        // Enable the question if its id is in questionIds, otherwise disable it
        question.isDisable = !questionIdsSet.has(question.id);
      }
    });
  }

  get name() {
    return this.castNumber(this.data.name);
  }

  get isFormDynamic(): boolean {
    return isFormDynamic(this.questions);
  }

  compare(instance: ResponseForm): number {
    return this.id - instance.id;
  }

  serialize(): ResponseFormSerializer {
    return this.data;
  }

  static default(): ResponseForm {
    return new ResponseForm({
      id: 0,
      questions: [
        {
          id: 1,
          text: "Comments",
          answer_type: QuestionAnswerType.COMMENT_INPUT_FIELD,
          sequence: 1,
          required: false,
          response_form: 0,
          final: true,
        },
      ],
      response_options_by_question_id: {
        "1": [],
      },
    });
  }
}

export { ResponseForm };
