import type { InfiniteData } from "@tanstack/react-query";
import type { NotificationsResponse } from "@/api";
import type { Notification } from "@/models";

const buildInfiniteNotifications = (
  data?: InfiniteData<NotificationsResponse>
): Notification[] => {
  const allNotifications: Notification[] = [];
  data?.pages?.forEach((page) => {
    if (page.data) {
      allNotifications.push(...page.data);
    }
  });
  return allNotifications;
};

export { buildInfiniteNotifications };
