import type { DynamicOptionsReturn } from "@/api";
import type { Question, ResponseForm } from "@/models";
import type { SelectOption } from "@/components/Select";
import { useCallback, useMemo, useRef, useState } from "react";
import { QuestionAnswerType } from "@/models";
import { useTaskTypes } from "@/api";
import {
  isFormValid,
  type FormValues,
  type QuestionValue,
  getFirstRequiredQuestion,
  updateEnabledQuestions,
} from "@/utils";

const useResponseFormHandler = (
  responseForm?: ResponseForm,
  dynamicOptions?: DynamicOptionsReturn
) => {
  const { data: taskTypes } = useTaskTypes();
  const [formValues, setFormValuesState] = useState<FormValues>({});
  const [dirtyQuestions, setDirtyQuestions] = useState<{
    [key: number]: boolean;
  }>({});
  const formValuesRef = useRef<FormValues>({});
  const isDynamicForm = responseForm?.isFormDynamic;

  const setFormValues = useCallback(
    (
      valOrFn: FormValues | ((val: FormValues) => FormValues),
      prevailLocalFormValue = false
    ) => {
      const newFormValue =
        typeof valOrFn === "function"
          ? valOrFn(formValuesRef.current)
          : valOrFn;

      const formValue = prevailLocalFormValue
        ? { ...newFormValue, ...formValuesRef.current }
        : newFormValue;

      setFormValuesState(formValue);
      formValuesRef.current = formValue;

      // --- Dynamic Form --- //
      // Recalculate enabled questions
      if (isDynamicForm && responseForm) {
        const firstRequiredQuestion = getFirstRequiredQuestion(
          responseForm.questions
        );
        if (firstRequiredQuestion) {
          updateEnabledQuestions(
            firstRequiredQuestion.id,
            responseForm,
            formValuesRef,
            isDynamicForm
          );
        }
      }
    },
    [
      setFormValuesState,
      formValuesRef,
      isDynamicForm,
      responseForm,
      getFirstRequiredQuestion,
      updateEnabledQuestions,
    ]
  );

  const getIsQuestionDirty = useCallback(
    (question: Question): boolean => dirtyQuestions[question.id] || false,
    [dirtyQuestions]
  );
  const setIsQuestionDirty = useCallback(
    (question: Question, isDirty = true) => {
      setDirtyQuestions((x) => ({ ...x, [question.id]: isDirty }));
    },
    [setDirtyQuestions]
  );
  const setQuestionValue = useCallback(
    (question: Question, value: QuestionValue) => {
      setFormValues((formValues) => ({
        ...formValues,
        [question.id]: value ?? "",
      }));
      setIsQuestionDirty(question);

      // -- Dynamic Form -- //
      if (responseForm && isDynamicForm) {
        const firstRequiredQuestion = getFirstRequiredQuestion(
          responseForm.questions
        );
        if (firstRequiredQuestion) {
          updateEnabledQuestions(
            firstRequiredQuestion.id,
            responseForm,
            formValuesRef,
            isDynamicForm
          );
        }
      }
    },
    [setFormValues, setIsQuestionDirty]
  );

  const setAllQuestionsAsDirty = useCallback(() => {
    const acc: { [key: number]: boolean } = {};
    const dirtyQuestions =
      responseForm?.questions.reduce((acc, question) => {
        acc[question.id] = true;
        return acc;
      }, acc) || acc;
    setDirtyQuestions(dirtyQuestions);
  }, [setDirtyQuestions, responseForm?.questions]);

  const getQuestionValue = useCallback(
    (question: Question): QuestionValue => formValues[question.id],
    [formValues]
  );

  const getOptionsForQuestion = useCallback(
    (question: Question): SelectOption<string | undefined>[] | undefined => {
      let value;

      if (
        question.hasDynamicOptions &&
        question.answerType === QuestionAnswerType.POSITIVE_RESPONSE_CODE &&
        dynamicOptions
      ) {
        value = dynamicOptions[question.id];
      } else if (question.answerType === QuestionAnswerType.FOLLOW_UP_TASK) {
        value = taskTypes?.map((x) => ({ id: x.id, text: x.name }));
      }
      // All other scenarios
      else {
        value = responseForm?.getResponseOptions(question.id);
      }

      const options: SelectOption<string | undefined>[] | undefined =
        value?.map((x) => ({ value: String(x.id), label: x.text }));

      // Natural sort options by label
      return options?.sort((firstOption, secondOption) =>
        firstOption?.label.localeCompare(secondOption?.label, "en-US", {
          numeric: true,
          sensitivity: "base",
        })
      );
    },
    [dynamicOptions, responseForm, taskTypes]
  );

  // If the form is dynamic, we need to filter out disabled questions before submitting
  // Otherwise, we submit all questions
  const getAnsweredQuestionsToSubmit = useCallback(
    () =>
      Object.entries(formValuesRef.current)
        .filter(([questionId]) => {
          if (isDynamicForm) {
            const question = responseForm?.questions.find(
              (q) => q.id === Number(questionId)
            );
            // Exclude disabled questions in dynamic forms
            return question && !question.isDisable;
          }
          return true; // Include all questions in non-dynamic forms
        })
        .map(([questionId, response]) => ({
          id: Number(questionId),
          response,
        })),
    [formValuesRef, isDynamicForm, responseForm?.questions]
  );

  const isReadyForSubmission = useMemo(
    () =>
      responseForm === undefined ||
      isFormValid(
        responseForm?.questions,
        formValues,
        responseForm?.isFormDynamic
      ),
    [responseForm, formValues, isDynamicForm]
  );

  return {
    formValuesRef,
    getQuestionValue,
    getIsQuestionDirty,
    getOptionsForQuestion,
    setIsQuestionDirty,
    setQuestionValue,
    setAllQuestionsAsDirty,
    setFormValues,
    isReadyForSubmission,
    formValues,
    getAnsweredQuestionsToSubmit,

    // -- Dynamic logic -- //
    isDynamicForm,
  };
};

export { useResponseFormHandler };
