import type { NotificationSerializer } from "@/utils/damage-prevention";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Notification } from "@/models";
import { UrbintApi } from "@/utils/UrbintApi";
import { queryClient } from "@/api/client";
import { handleApiResponse } from "@/api/helpers";

const api = new UrbintApi();
const notificationsIdent = "notifications";

type NotificationsResponse = {
  data: Notification[];
  nextCursor?: number;
  totalUnread?: number;
};

type NotificationsResults = {
  notifications: NotificationSerializer[];
  total_unread: number;
};

const fetchNotifications = (pageNumber = 1) =>
  api
    .getPage<NotificationSerializer>({
      endPoint: "notifications",
      page: pageNumber,
    })
    .then(handleApiResponse)
    .then((res) => {
      const rawResults = res.results as unknown as NotificationsResults;

      return {
        data: rawResults.notifications.map((r) => new Notification(r)),
        nextCursor: res.next ? pageNumber + 1 : undefined,
        totalUnread: rawResults.total_unread,
      };
    });

const useNotifications = () =>
  useInfiniteQuery<NotificationsResponse>({
    queryKey: [notificationsIdent],
    queryFn: ({ pageParam = 1 }) => fetchNotifications(pageParam),
    refetchInterval: 1000 * 60, // 1 min polling
    getNextPageParam: (lastPage) => lastPage.nextCursor,
  });

const invalidateNotificationsQuery = () =>
  queryClient.invalidateQueries([notificationsIdent]);

export { notificationsIdent, useNotifications, invalidateNotificationsQuery };

export type { NotificationsResponse };
