/* eslint-disable camelcase */
import type { Task } from "@/models";
import type {
  ResponseFormSerializer,
  TaskTypeSerializer,
} from "@/utils/damage-prevention";
import type { UseQueryResult } from "@tanstack/react-query";
import { queryClient } from "@/api/client";
import { prefetchUseResponseForm, prefetchDynamicOptions } from "@/api";

const prefetchResponseForms = async (task: Task) => {
  const { taskTypeId, id: taskId } = task ?? {};

  // For each task, get the Task type already prefetched from cache.
  const taskType = queryClient.getQueryData<
    UseQueryResult<TaskTypeSerializer, unknown>
  >(["task_types", taskTypeId]);

  if (taskType?.data?.response_form) {
    const {
      data: { response_form },
    } = taskType;

    // Prefetch and get Response form information from cache.
    // Is not possible to use Tan Stack Queries hook inside a forEach

    await prefetchUseResponseForm(response_form);
    const responseForm = queryClient.getQueryData<
      UseQueryResult<ResponseFormSerializer, unknown>
    >(["response_forms", response_form]);

    if (responseForm?.data?.id && taskId) {
      const {
        data: { id: responseFormId },
      } = responseForm;
      await prefetchDynamicOptions({ taskId, responseFormId });
    }
  }
};

export { prefetchResponseForms };
