import type { Notification } from "@/models/Notification";
import { useEffect, useMemo, useRef } from "react";
import { Subheading } from "@urbint/silica";
import { useDamagePreventionAuth } from "@/hooks";
import { useNotifications } from "@/api";
import { Spinner } from "@/common/Spinner";
import { NotificationItem } from "./NotificationItem";
import { PanelHeader } from "./PanelHeader";
import { buildInfiniteNotifications } from "./Notifications.utils";

const option = {
  root: null,
  rootMargin: "50px",
  threshold: 0,
};

const NotificationsPanel = () => {
  const { currentUser } = useDamagePreventionAuth();
  const { data, isInitialLoading, fetchNextPage, hasNextPage } =
    useNotifications();

  const notifications = buildInfiniteNotifications(data);
  const loader = useRef(null);

  const [readNotifications = [], unreadNotifications = []] = useMemo(() => {
    notifications.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
    return notifications.reduce<Notification[][]>(
      (acc, notification) => {
        acc[notification.read ? 0 : 1]!.push(notification);
        return acc;
      },
      [[], []]
    );
  }, [notifications]);
  const sortedNotifications = [...unreadNotifications, ...readNotifications];
  useEffect(() => {
    const handleObserver = (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (target?.isIntersecting && !isInitialLoading && hasNextPage) {
        fetchNextPage();
      }
    };

    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);

    return () => {
      observer.disconnect();
    };
  }, [data, hasNextPage, isInitialLoading, fetchNextPage]);

  return (
    <>
      <PanelHeader
        unreadNotifications={data?.pages[0]?.totalUnread}
        notificationsCount={notifications.length}
      />
      <div className="flex flex-col min-w-[320px] max-w-[640px] md:max-h-[60vh] w-auto overflow-auto">
        {sortedNotifications.length > 0 ? (
          sortedNotifications.map((notification, idx) => (
            <div key={notification.id}>
              <NotificationItem
                currentUser={currentUser}
                notification={notification}
              />
              {/* Only show `<hr />` if there's another notification afterward. */}
              {idx < sortedNotifications.length - 1 && <hr />}
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center p-4 select-none">
            <Subheading className="font-body-text text-gray-500">
              No notifications
            </Subheading>
          </div>
        )}
        {hasNextPage && (
          <div
            className="loading h-16 w-full flex items-center justify-center"
            ref={loader}
          >
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export { NotificationsPanel };
