import type { SelectOption } from "@/components/Select";
import type { TypeheadSelectProps } from "./TypeheadSelect.types";
import { useState, useEffect } from "react";
import { Combobox } from "@headlessui/react";
import cx from "classnames";
import { isEmpty } from "lodash";

import { Icon } from "@urbint/silica";
import { useAriaId } from "@/hooks";
import { Checkbox } from "@/common/Checkbox";
import { Tooltip } from "@/common/Tooltip";
import { SelectInputWrapper } from "./SelectInputWrapper";
import { SelectOptionsWrapper } from "./SelectOptionsWrapper";
import { allVisibleNonDisabledOptions } from "./TypeheadSelect.utils";
import {
  EXCLUDE_TITLE,
  EXCLUDE_TOOLTIP_TEXT,
  INCLUDE_TITLE,
} from "./TypeheadSelect.constants";
import { TypeaheadProvider } from "./TypeheadSelectProvider";

const TypeaheadSelect = ({
  name,
  className = "",
  onChange,
  /** Options to select from */
  includeOptions = [],
  excludeOptions = [],
  /** Values already selected from options  */
  includeValue = [],
  excludeValue = [],
  includePlaceholder,
  excludePlaceholder,
  dataTestId = "TypeaheadSelectTestId",
}: TypeheadSelectProps<string>) => {
  const ariaId = useAriaId();
  const [includeOptionFilter, setIncludeOptionFilter] = useState("");
  const [excludeOptionFilter, setExcludeOptionFilter] = useState("");
  const [showExclude, setShowExclude] = useState(!isEmpty(excludeValue));

  const [selectedExcludeOptions, setSelectedExcludeOptions] = useState(
    () =>
      excludeOptions?.filter((option: SelectOption<string>) =>
        excludeValue.includes(option.value)
      ) || []
  );

  const [selectedIncludeOptions, setSelectedIncludeOptions] = useState(
    () =>
      includeOptions?.filter((option: SelectOption<string>) =>
        includeValue.includes(option.value)
      ) || []
  );

  useEffect(() => {
    const include = selectedIncludeOptions.map(
      (option: SelectOption<string>) => option.value
    );

    const exclude = selectedExcludeOptions.map(
      (option: SelectOption<string>) => option.value
    );

    onChange(include, exclude);
  }, [selectedIncludeOptions, selectedExcludeOptions]);

  const handleIncludeSelectAll = (
    isChecked: boolean,
    allVisibleOptions: SelectOption<string>[]
  ) => {
    if (isChecked) {
      // Join select all options
      setSelectedIncludeOptions((prevOptions) =>
        Array.from(
          new Set([
            ...prevOptions,
            ...allVisibleNonDisabledOptions(
              allVisibleOptions,
              selectedExcludeOptions
            ),
          ])
        )
      );
    } else {
      // Deselect only the visible options
      setSelectedIncludeOptions((prevOptions) =>
        prevOptions.filter(
          (prevOption) =>
            !allVisibleNonDisabledOptions(
              allVisibleOptions,
              selectedExcludeOptions
            ).includes(prevOption)
        )
      );
    }
  };

  const handleExcludeSelectAll = (
    isChecked: boolean,
    allVisibleOptions: SelectOption<string>[]
  ) => {
    if (isChecked) {
      // Join select all options
      setSelectedExcludeOptions((prevOptions) =>
        Array.from(
          new Set([
            ...prevOptions,
            ...allVisibleNonDisabledOptions(
              allVisibleOptions,
              selectedIncludeOptions
            ),
          ])
        )
      );
    } else {
      // Deselect only the visible options
      setSelectedExcludeOptions((prevOptions) =>
        prevOptions.filter(
          (prevOption) =>
            !allVisibleNonDisabledOptions(
              allVisibleOptions,
              selectedIncludeOptions
            ).includes(prevOption)
        )
      );
    }
  };

  const handleShowExclude = (isChecked: boolean) => {
    setShowExclude(isChecked);

    // Clear selected exclude options if exclude is unchecked
    if (!isChecked) {
      setSelectedExcludeOptions([]);
    }
  };

  const multiSelectId = `urbint-typeahead-multiselect-${ariaId}`;

  return (
    <TypeaheadProvider name={name}>
      <label
        htmlFor="include"
        className="font-semibold text-black text-opacity-88 text-sm"
      >
        {INCLUDE_TITLE}
      </label>
      <Combobox
        value={selectedIncludeOptions}
        onChange={(options) => setSelectedIncludeOptions(options)}
        multiple
        as="div"
        className={cx("relative mt-2", className)}
        id={multiSelectId}
      >
        <SelectInputWrapper
          type="include"
          testId={dataTestId}
          inputPlaceholder={
            includeValue?.length === 0 ? includePlaceholder : undefined
          }
          selectedOptions={selectedIncludeOptions}
          setSelectedOptions={setSelectedIncludeOptions}
          setOptionFilter={setIncludeOptionFilter}
        />
        <SelectOptionsWrapper
          type="include"
          options={includeOptions}
          optionsToDisable={selectedExcludeOptions}
          filterValue={includeOptionFilter}
          handleSelectAll={handleIncludeSelectAll}
          selectedOptions={selectedIncludeOptions}
        />
      </Combobox>
      <div className="flex mt-4 items-center">
        <Checkbox
          id="exclude"
          checked={showExclude}
          onChange={(e) => handleShowExclude(e)}
          className="shrink-0 mr-2"
        />
        <label
          htmlFor="exclude"
          className="font-semibold text-black text-opacity-88 text-sm"
        >
          {EXCLUDE_TITLE}
        </label>
        <Tooltip title={EXCLUDE_TOOLTIP_TEXT}>
          <Icon name="info_circle_outline" className="ml-1" />
        </Tooltip>
      </div>
      {/** Exclude section  */}
      {showExclude && (
        <Combobox
          value={selectedExcludeOptions}
          onChange={(options) => setSelectedExcludeOptions(options)}
          multiple
          as="div"
          className={cx("relative mt-2", className)}
          id={multiSelectId}
        >
          <SelectInputWrapper
            type="exclude"
            testId={dataTestId}
            inputPlaceholder={
              excludeValue?.length === 0 ? excludePlaceholder : undefined
            }
            selectedOptions={selectedExcludeOptions}
            setSelectedOptions={setSelectedExcludeOptions}
            setOptionFilter={setExcludeOptionFilter}
          />
          <SelectOptionsWrapper
            type="exclude"
            options={excludeOptions}
            optionsToDisable={selectedIncludeOptions}
            filterValue={excludeOptionFilter}
            handleSelectAll={handleExcludeSelectAll}
            selectedOptions={selectedExcludeOptions}
          />
        </Combobox>
      )}
    </TypeaheadProvider>
  );
};

export { TypeaheadSelect };
