import type { ActivitySerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";
import { ActivityTypes } from "@/pages/TicketPage/Activities/Activities.constants";

class Activity extends UrbintModel<ActivitySerializer> {
  get changedFields() {
    return this.castString(this.data.changed_fields);
  }

  get changedFieldsJSON() {
    return JSON.parse(this.changedFields);
  }

  get date() {
    return this.castDate(this.data.datetime);
  }

  get id() {
    return this.castNumber(this.data.id);
  }

  get taskId() {
    return (
      this.castNumber(this.object.fields.task || this.object.pk) || undefined
    );
  }

  get ticketId() {
    return this.castNumber(this.object.fields.ticket);
  }

  get ticketNumber() {
    return this.castString(this.object.fields.number);
  }

  get submittedForm() {
    return this.activityType === "answerset_submit";
  }

  get attachmentDeleted() {
    return this.data.attachmentDeleted;
  }

  get objectId() {
    return this.castString(this.data.object_id);
  }

  get userId() {
    return this.data.user ? this.castNumber(this.data.user) : undefined;
  }

  get objectJSON() {
    if (this.data.object_json_repr) {
      return JSON.parse(this.castString(this.data.object_json_repr));
    }
    return [{}];
  }

  get object() {
    return this.objectJSON[0];
  }

  get modelName() {
    return this.object.model;
  }

  get modelType() {
    switch (this.modelName) {
      case "damage_prevention.ticket":
      case "damage_prevention.ticketcomment":
      case "damage_prevention.ticketversion":
      case "damage_prevention.positiveresponse":
      case "damage_prevention.forwardticketrecord":
        return "ticket";
      case "damage_prevention.task":
      case "damage_prevention.taskcomment":
      case "damage_prevention.taskattachment":
      case "damage_prevention.taskphoto":
      case "damage_prevention.answerset":
        return "task";
      default:
        return "not-found";
    }
  }

  get commentMessage() {
    if (this.predicate !== "commented") {
      return "";
    }
    return this.castString(this.objectJSON[0].fields.comment);
  }

  get commentMessageJSX() {
    if (this.predicate !== "commented") {
      return null;
    }
    const lines = this.castArray<string>(
      this.object.fields.comment.split("\n")
    );
    return (
      <>
        {lines.map((line) => (
          <div key={`${line} - line`}>
            {line}
            <br />
          </div>
        ))}
      </>
    );
  }

  get imageURL() {
    return this.castString(this.object.fields.photo);
  }

  get ticketVersion() {
    return this.castString(this.object.fields.version);
  }

  get activityType() {
    return this.data.activity_type;
  }

  get predicate() {
    switch (this.activityType) {
      case ActivityTypes.TASK_ASSIGNMENT:
        return "assigned";
      case ActivityTypes.TASK_CREATION:
      case ActivityTypes.TICKET_CREATION:
        return "created";
      case ActivityTypes.TICKET_VERSION:
        return "versioned";
      case ActivityTypes.TASK_COMMENT:
      case ActivityTypes.TICKET_COMMENT:
        return "commented";
      case ActivityTypes.TICKET_STATUS_CHANGED:
        return "status_changed";
      case ActivityTypes.AUTOMATION_ENGINE_CREATE:
        return "automation_engine_create";
      case ActivityTypes.AUTOMATION_ENGINE_UPDATE:
        return "automation_engine_update";
      case ActivityTypes.TASK_ATTACHMENT:
        return "photographed";
      case ActivityTypes.TASK_ATTACHMENT_DELETED:
        return "attachment_deleted";
      case ActivityTypes.ANSWERSET_SUBMIT:
      case ActivityTypes.ANSWERSET_EDIT:
        return "responded";
      case ActivityTypes.FORWARD_TICKET:
        return "forward_ticket";
      case ActivityTypes.FORWARD_TICKET_RESPONSE:
        return "forward_ticket_response";
      case ActivityTypes.POSITIVE_RESPONSE:
        return "positive_response";
      case ActivityTypes.POSITIVE_RESPONSE_RESPONSE:
        return "positive_response_response";
      case ActivityTypes.TASK_STATUS_UPDATED:
        return "task_status_updated";
      case ActivityTypes.TICKET_EXPIRATION_CHANGED:
        return "ticket_expiration_changed";
      case ActivityTypes.TICKET_SERVICE_CENTER_CHANGED:
        return "ticket_service_center_changed";
      default:
        return "ignore";
    }
  }

  get assignedFromAndTo(): [string, string] {
    return this.changedFieldsJSON?.assignee;
  }

  get taskStepFromAndTo(): [string, string] {
    return this.changedFieldsJSON?.task_step;
  }

  get statusFromAndTo(): [string, string] {
    return this.changedFieldsJSON?.status;
  }

  get eventTypeLabel() {
    return this.castString(this.data.event_type_label);
  }

  compare(instance: Activity) {
    return instance.date.getTime() - this.date.getTime();
  }

  serialize(): ActivitySerializer {
    return this.data;
  }
}

export { Activity };
