import type { activityPhraseMapProps } from "./ActivityListTicketItem.types";
import { ActivityTypes } from "@/pages/TicketPage/Activities/Activities.constants";
import {
  getAutomationEngineCreatePhrase,
  getAutomationEngineUpdatePhrase,
  getForwardTicketPhrase,
  getFowardTicketResponsePhrase,
  getPositiveResponsePhrase,
  getPositiveResponseResponsePhrase,
  getTicketCommentPhrase,
  getTicketStageChangedPhrase,
  getTicketStatusChangedPhrase,
  getTicketVersionPhrase,
  getTicketExpirationDateChangedPhrase,
  getTicketServiceCenterChangedPhrase,
} from "./ActivityListTicketItem.utils";

const activityPhraseMap: activityPhraseMapProps = {
  [ActivityTypes.AUTOMATION_ENGINE_CREATE]: getAutomationEngineCreatePhrase,
  [ActivityTypes.AUTOMATION_ENGINE_UPDATE]: getAutomationEngineUpdatePhrase,
  [ActivityTypes.POSITIVE_RESPONSE]: getPositiveResponsePhrase,
  [ActivityTypes.POSITIVE_RESPONSE_RESPONSE]: getPositiveResponseResponsePhrase,
  [ActivityTypes.FORWARD_TICKET]: getForwardTicketPhrase,
  [ActivityTypes.FORWARD_TICKET_RESPONSE]: getFowardTicketResponsePhrase,
  [ActivityTypes.TICKET_VERSION]: getTicketVersionPhrase,
  [ActivityTypes.TICKET_COMMENT]: getTicketCommentPhrase,
  [ActivityTypes.TICKET_STATUS_CHANGED]: getTicketStatusChangedPhrase,
  [ActivityTypes.TICKET_STAGE_CHANGED]: getTicketStageChangedPhrase,
  [ActivityTypes.TICKET_EXPIRATION_CHANGED]:
    getTicketExpirationDateChangedPhrase,
  [ActivityTypes.TICKET_SERVICE_CENTER_CHANGED]:
    getTicketServiceCenterChangedPhrase,
};

const NO_EXPIRATION_DATE_SET = "None";

// Service Center requires a different approach, as it is a Many-to-Many relationship on the BE side
const SERVICE_CENTER_REMOVE = "Many-to-Many Remove";
const SERVICE_CENTER_ADD = "Many-to-Many Add";

export {
  activityPhraseMap,
  NO_EXPIRATION_DATE_SET,
  SERVICE_CENTER_REMOVE,
  SERVICE_CENTER_ADD,
};
