import type { ResponseOptionSerializer } from "@/utils/damage-prevention";
import { UrbintModel } from "@/utils/UrbintModel";

class ResponseOption extends UrbintModel<ResponseOptionSerializer> {
  get id() {
    return this.castNumber(this.data.id);
  }

  get text() {
    return this.castString(this.data.text);
  }

  get questionId() {
    return this.castNumber(this.data.question);
  }

  get nextQuestions() {
    return this.castArray<number>(this.data.next_questions);
  }

  compare(instance: ResponseOption): number {
    return this.id - instance.id;
  }

  serialize(): ResponseOptionSerializer {
    return this.data;
  }
}

export { ResponseOption };
