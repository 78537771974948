import type { SavedViewSelectDropdownProps } from "./SavedViewSelectDropdown.types";
import type { SavedView } from "@/models";
import { useCallback, useEffect, useRef, useState } from "react";
import { Transition } from "@headlessui/react";

import { Icon } from "@urbint/silica";

import { DropdownSelect, SelectButton } from "@/components/Select";
import { useTicketViewEditor } from "@/components/TicketViewEditorProvider";
import { SearchInput } from "@/common/SearchInput";

import {
  useCheckDateRangeWarning,
  useOnlineStatus,
  useWindowEventListener,
} from "@/hooks";
import {
  MY_OPEN_TICKETS_SAVED_VIEW_NAME,
  MY_RECENTLY_CLOSED_TICKETS_SAVED_VIEW_NAME,
  useSavedViews,
} from "@/api";
import { useToasts } from "@/components/Toasts";
import { getDropdownOptions } from "./SavedViewSelectDropdown.utils";

const SavedViewSelectDropdown = ({
  onViewSelect,
}: SavedViewSelectDropdownProps) => {
  const [isSearchOpen, setSearchOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { searchTerm, setSearchTerm } = useTicketViewEditor();
  const { savedView: selectedSavedView, isDirty } = useTicketViewEditor();
  const { data: savedViews = [] } = useSavedViews();
  const isOnline = useOnlineStatus();
  const { isInDateRangeWithinWarningLimit, limitDays } =
    useCheckDateRangeWarning();
  const { addToast } = useToasts();

  // if after refresh there's a search term then show the search
  useEffect(() => {
    if (searchTerm) {
      setSearchOpen(true);
    }
  }, [searchTerm]);

  const options = getDropdownOptions();

  // If app goes offline and selected Saved View is neither "My Open Tickets" or "My Recently Closed Tickets"
  // Switch for the default offline Saved View "My Open Tickets"
  if (
    !isOnline &&
    selectedSavedView?.name !== MY_OPEN_TICKETS_SAVED_VIEW_NAME &&
    selectedSavedView?.name !== MY_RECENTLY_CLOSED_TICKETS_SAVED_VIEW_NAME
  ) {
    const offlineSavedView = savedViews.find(
      (savedViewObj: SavedView) =>
        savedViewObj.name === MY_OPEN_TICKETS_SAVED_VIEW_NAME
    );
    if (offlineSavedView) {
      onViewSelect(offlineSavedView);
    }
  }

  useWindowEventListener(
    "keydown",
    (e: KeyboardEvent) => {
      if (e.key === "/") {
        setSearchOpen(true);
      }
    },
    undefined,
    [setSearchOpen]
  );

  const onBack = useCallback(() => {
    setSearchOpen(false);
    setSearchTerm("");
  }, [setSearchOpen, setSearchTerm]);

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleBlur = () => !searchTerm && setSearchOpen(false);

  const onChangeView = (view: SavedView) => {
    onViewSelect(view);

    if (view.filters && isInDateRangeWithinWarningLimit(view.filters)) {
      addToast(
        `Your current view is now limited to the last ${limitDays} days. Please update your date selection to view data`,
        "warning"
      );
    }
  };

  return (
    <div className="w-full md:w-[21rem] flex">
      <Transition
        className="flex items-center w-full font-semibold bg-white border rounded transform-gpu h-9"
        show={!isSearchOpen}
        leave="transition duration-100"
        leaveTo="-translate-x-full"
        enter="transition duration-100"
        enterFrom="-translate-x-full"
      >
        <div data-testid="saved-views-dropdown-list" className="flex-1 min-w-0">
          <DropdownSelect
            optionsClassName="max-w-full md:max-w-lg"
            value={selectedSavedView}
            comparator={(v, ov) => v?.id === ov?.id}
            options={options}
            onChange={onChangeView}
            buttonComponent={({ selectedOption }) => (
              <SelectButton className="justify-start w-full pr-3">
                <div className="font-semibold truncate text-neutral-shade-primary">
                  {selectedOption?.label}
                </div>
                {isDirty && (
                  <div className="ml-2 font-sans text-neutral-shade-secondary">
                    (unsaved)
                  </div>
                )}
                <div className="flex-1" />
                <div className="w-4 h-4 ml-2">
                  <Icon name="chevron_down" />
                </div>
              </SelectButton>
            )}
          />
        </div>
        {isOnline && (
          <button
            type="button"
            data-testid="search-tickets-button"
            className="flex items-center justify-center w-10 border-l border-gray-100 border-solid rounded-r h-9"
            onClick={() => setSearchOpen(true)}
          >
            <Icon name="search" />
          </button>
        )}
      </Transition>
      <Transition
        show={isSearchOpen}
        className="flex w-full h-10 max-w-full transform-gpu"
        enter="transition duration-100"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        afterEnter={() => inputRef.current?.focus()}
      >
        <button
          type="button"
          className="flex items-center justify-center w-10 h-10 text-xl md:text-black"
          onClick={onBack}
        >
          <Icon name="chevron_left" />
        </button>
        <SearchInput
          testId="search-tickets-input"
          withSearchIcon={false}
          inputRef={inputRef}
          onSearch={handleSearch}
          onClear={handleClearSearch}
          onBlur={handleBlur}
          placeholder="Search..."
          theme={{ wrapper: "w-full" }}
        />
      </Transition>
    </div>
  );
};

export { SavedViewSelectDropdown };
