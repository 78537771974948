import { BodyText } from "@urbint/silica";
import { Link } from "react-router-dom";

type TextWithLinksProps = {
  text: string;
};
const URL_PATTERN = "(https?:\\/\\/[^\\s]+|www\\.[^\\s]+)";
// Identify links in a string and makes them clickable
function TextWithLinks({ text, ...props }: TextWithLinksProps): JSX.Element {
  const urlPattern = new RegExp(URL_PATTERN, "g");
  if (!urlPattern.test(text)) {
    return <BodyText {...props}>{text}</BodyText>;
  }
  // Split the text into array of strings where in every even index there will be a text and every odd index there will be a URL
  const parts = text.trim().split(urlPattern);
  return (
    <BodyText {...props}>
      {parts.map((part, index) => {
        const key = `${part}-${index}`;
        // Checking if it's a URL or not by checking if the index is odd or even
        if (index % 2 === 1) {
          return (
            <Link
              to={{
                pathname: part.startsWith("www.") ? `https://${part}` : part,
              }}
              key={key}
              className="text-brand-40 break-all hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              {part}
            </Link>
          );
        }
        return part;
      })}
    </BodyText>
  );
}

export { TextWithLinks };
