import type { TaskResponseFormSectionProps } from "./TaskResponseFormSection.types";
import cx from "classnames";
import { IonIcon } from "@ionic/react";
import { alertCircle, alertOutline, pencil } from "ionicons/icons";
import { Tooltip } from "@/common/Tooltip";
import { AutoSaveStatus } from "@/components/FormBuilder";
import { formatCalendarDate, formatClockTime } from "@/format";
import { QuestionAnswerType } from "@/models";
import { useOnlineStatus, useResponseFormLogic } from "@/hooks";
import {
  BANNER_TYPE,
  NetworkStatusBanner,
} from "@/components/NetworkStatusBanner";
import { Button } from "@/common/Button";
import { TaskDetailSection } from "../TaskDetailSection";
import { TaskDetailSectionHeader } from "../TaskDetailSectionHeader";
import { TaskResponseFormQuestion } from "../TaskResponseFormQuestion";
import { TaskResponseFormSubheader } from "./TaskResponseFormSubheader";
import { TaskResponseFormQuestionInput } from "./TaskResponseFormQuestionInput";
import { TaskResponseFormMultiPRQuestion } from "./TaskResponseFormMultiPRQuestion";

const TaskResponseFormSection = ({
  task,
  ticketNumber,
}: TaskResponseFormSectionProps) => {
  const {
    isInitialLoading,
    isSubmitted,
    questions,
    isEditing,
    canEditResponseForm,
    getQuestionValue,
    setQuestionValue,
    getOptionsForQuestion,
    getStringifiedAnswerForQuestion,
    getStringifiedResponsesForMultiPRQuestion,
    getIsQuestionDirty,
    setIsQuestionDirty,
    startUserEditing,
    cancelUserEditing,
    submittedAt,
    autosaveForm,
    autosavedAt,
    isAutosaving,
    isAutosaveError,
    hasAttemptedFormSubmission,
    isReadyForSubmission,
    isSubmitting,
    submitForm,
    dynamicOptions,
    answerResponseFormIsPaused,
    // --- Offline Support --- //
    isSubmittedOffline,
    isDynamicForm,
  } = useResponseFormLogic(task, ticketNumber);

  const isOnline = useOnlineStatus();

  let submitButtonLabel;
  if (isSubmitting && isSubmitted) {
    submitButtonLabel = "Saving...";
  } else if (isSubmitted) {
    submitButtonLabel = "Save changes";
  } else if (!isOnline && isSubmitting && isSubmittedOffline) {
    submitButtonLabel = "Submitted offline";
  } else if (isSubmitting) {
    submitButtonLabel = "Submitting form...";
  } else {
    submitButtonLabel = "Submit form and complete task";
  }

  const showErrorInsteadOfButton =
    !isSubmitting && hasAttemptedFormSubmission && !isReadyForSubmission;

  return (
    <TaskDetailSection>
      <TaskDetailSectionHeader className="flex items-center">
        <span>Response form</span>
        <span className="text-sm ml-auto text-neutral-shade-secondary font-normal flex items-center">
          {isSubmitted && canEditResponseForm && !isEditing && (
            <button
              type="button"
              onClick={startUserEditing}
              className="flex items-center"
              data-testid="edit-response-form"
            >
              <IonIcon icon={pencil} className="icon-bold text-md mr-1" />
              Edit
            </button>
          )}
          {isSubmitted && canEditResponseForm && isEditing && (
            <button
              type="button"
              onClick={cancelUserEditing}
              className="flex items-center"
            >
              Cancel
            </button>
          )}
          {isEditing &&
            isAutosaving &&
            !answerResponseFormIsPaused &&
            "Saving..."}
          {isEditing && isAutosaveError && !isAutosaving && (
            <Tooltip title="There was an error autosaving." origin="right">
              <IonIcon
                icon={alertCircle}
                className="mr-1 text-base text-system-error-40"
              />
              Unsaved changes
            </Tooltip>
          )}
          {isEditing && !isAutosaveError && autosavedAt && !isAutosaving && (
            <AutoSaveStatus
              isAutoSaving={isAutosaving}
              autoSavedAt={autosavedAt}
              autoSaveHasError={isAutosaveError}
            />
          )}
        </span>
      </TaskDetailSectionHeader>
      {!isOnline && !showErrorInsteadOfButton && (
        <NetworkStatusBanner
          type={
            isSubmittedOffline
              ? BANNER_TYPE.OFFLINE_FORM_SUBMITTED
              : BANNER_TYPE.OFFLINE_FORM_DEFAULT
          }
          className="mb-6"
        />
      )}
      {!isSubmitted && !canEditResponseForm && (
        <TaskResponseFormSubheader>
          The form has not been submitted.
        </TaskResponseFormSubheader>
      )}
      {isEditing && !isInitialLoading && questions !== undefined && (
        <form data-testid="response-form" onSubmit={submitForm}>
          {questions.map((question, ix) => (
            <TaskResponseFormQuestionInput
              key={question.id}
              testId={`task-response-question-${ix}`}
              question={question}
              disabled={isSubmitting || question.isDisable}
              value={getQuestionValue(question)}
              options={getOptionsForQuestion(question)}
              isDirty={getIsQuestionDirty(question)}
              onBlur={() => {
                setIsQuestionDirty(question);
                autosaveForm();
              }}
              onChange={(val) => setQuestionValue(question, val)}
              dynamicOptions={dynamicOptions}
              hasAttemptedFormSubmission={hasAttemptedFormSubmission}
            />
          ))}
          <div className="mt-8">
            {!showErrorInsteadOfButton && (
              <Button
                type="submit"
                variant="primary"
                disabled={
                  isDynamicForm
                    ? !isReadyForSubmission || isSubmitting
                    : isSubmitting
                }
                className="w-full"
                data-testid="submit-form-button"
              >
                {submitButtonLabel}
              </Button>
            )}
            {showErrorInsteadOfButton && (
              <p
                className="flex items-center bg-system-error-10 ring-1 ring-system-error-30 p-2 leading-tight rounded"
                data-testid="form-errors-text"
              >
                <span className="bg-system-error-40 flex items-center justify-center h-6 w-6 rounded mr-2">
                  <IonIcon
                    icon={alertOutline}
                    className="bg-white rounded-full text-system-error-40"
                  />
                </span>
                There are errors in your form
              </p>
            )}
          </div>
        </form>
      )}
      {!isEditing &&
        !isInitialLoading &&
        isSubmitted &&
        questions !== undefined && (
          <>
            <TaskResponseFormSubheader
              className={cx("mb-6", {
                "inline-block": !isOnline,
              })}
            >
              Submitted on {formatCalendarDate(submittedAt)} at{" "}
              {formatClockTime(submittedAt)}
            </TaskResponseFormSubheader>
            {questions.map((question, ix) =>
              question.answerType === QuestionAnswerType.MULTI_PR_QUESTION ? (
                <TaskResponseFormMultiPRQuestion
                  key={question.id}
                  value={getStringifiedResponsesForMultiPRQuestion(
                    question,
                    dynamicOptions
                  )}
                />
              ) : (
                <TaskResponseFormQuestion
                  key={question.id}
                  testId={`task-response-question-${ix}-answer`}
                  question={question}
                  value={getStringifiedAnswerForQuestion(question)}
                  task={task}
                />
              )
            )}
          </>
        )}
    </TaskDetailSection>
  );
};

export { TaskResponseFormSection };
