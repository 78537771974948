import type { PositiveResponseModalProps } from "./PositiveResponseModal.types";
import { Dialog } from "@/components/Dialog";
import { MemberCodeResponse } from "./MemberCodeResponse";
import { organizeResponses } from "./PositiveResponseModal.utils";

const PositiveResponseModal = ({
  ticket,
  isOpen,
  onDidDismiss,
}: PositiveResponseModalProps) => {
  if (!ticket) {
    return null;
  }

  const { memberCodes = [] } = ticket;
  const groupedResponses = organizeResponses(
    memberCodes,
    ticket?.positiveResponses
  );

  return (
    <Dialog
      header="Positive response updates"
      isOpen={isOpen}
      onClose={onDidDismiss}
      theme={{
        content: "border-t-0",
      }}
    >
      <ul>
        {groupedResponses.map((memberCodeResponse) => {
          const { memberCode, responses } = memberCodeResponse;
          return (
            <li key={`${memberCode.id}-${responses.length}`} className="mb-3">
              <h4 className="text-base font-semibold">{memberCode.key}</h4>
              <MemberCodeResponse responses={responses} />
            </li>
          );
        })}
      </ul>
    </Dialog>
  );
};

export { PositiveResponseModal };
