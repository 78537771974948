import type { KeyboardEvent } from "react";
import type { AutoGrowingTextAreaProps } from "./AutoGrowingTextArea.types";
import { useCallback, useEffect, useRef, useState } from "react";

import { useDisposables } from "@/hooks";

const AutoGrowingTextArea = ({
  id,
  testId,
  value = "",
  onChange,
  onBlur,
  placeholder,
  className = "",
  disabled,
  onCtrlOrMetaEnter,
  label,
  hasError,
  maxCharacters,
}: AutoGrowingTextAreaProps) => {
  const textRef = useRef<HTMLTextAreaElement>(null);
  const measureRef = useRef<HTMLParagraphElement>(null);
  const [height, setHeight] = useState<undefined | number>(undefined);

  const recomputeHeight = useCallback(() => {
    if (!textRef.current || !measureRef.current) return;
    measureRef.current.style.width = `${textRef.current.offsetWidth}px`;
    setHeight(measureRef.current.offsetHeight);
  }, [setHeight]);

  const ctrlOrEnterHandler = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (onCtrlOrMetaEnter && (e.ctrlKey || e.metaKey) && e.code === "Enter") {
        e.preventDefault();
        onCtrlOrMetaEnter();
      }
    },
    [onCtrlOrMetaEnter]
  );

  const { requestAnimationFrame } = useDisposables();
  useEffect(() => {
    requestAnimationFrame(recomputeHeight);
  }, [value, recomputeHeight, requestAnimationFrame]);
  const classes = `
      rounded p-2 resize-none w-full leading-tight focus:outline-none border
      box-border font-sans min-h-[38px] ${className} ${
    disabled
      ? "placeholder-neutrals-disabled text-neutrals-disabled border-borders-disabled bg-white"
      : "placeholder-neutrals-primary text-neutrals-primary border-borders"
  } ${
    hasError
      ? "ring-system-error-30 ring-opacity-100 border-system-error-30"
      : ""
  }`;
  return (
    <>
      <div ref={measureRef} className={`${classes} invisible absolute`}>
        {(value || "a").split("\n").map((x) => (
          <p key={x}>{x || "a"}</p>
        ))}
      </div>
      {label && (
        <label
          htmlFor={id}
          className="font-semibold font-sans text-sm leading-5 mb-1 text-neutral-shade-secondary"
        >
          {label}
        </label>
      )}
      <textarea
        id={id}
        data-testid={testId}
        ref={textRef}
        disabled={disabled}
        onBlur={onBlur}
        rows={1}
        onChange={
          onChange &&
          ((e) => {
            if (maxCharacters) {
              const newValue = e.target.value.slice(0, maxCharacters);
              onChange(newValue);
            } else {
              onChange(e.target.value);
            }
          })
        }
        onKeyDown={ctrlOrEnterHandler}
        value={value}
        style={{ height: height ? `${height}px` : "auto" }}
        placeholder={placeholder}
        className={classes}
      />
    </>
  );
};

export { AutoGrowingTextArea };
