import type { DetailProps } from "./Detail.types";
import { camelCase } from "lodash";
import { TextWithLinks } from "@/utils";
import {
  labelClass,
  pClass,
  placeholderClass,
} from "../../TicketPageDetails.constants";

const Detail = ({
  label,
  value,
  layout = "horizontal",
  testId,
}: DetailProps) => {
  const isPlaceholder = !value;
  const spanTestId = camelCase(`${testId ?? label} Label`);
  const valueContent = isPlaceholder ? "—" : value;
  const valueTestId = camelCase(`${testId ?? label} Value`);
  const valueEl =
    typeof valueContent === "string" ? (
      <TextWithLinks text={valueContent} data-testid={valueTestId} />
    ) : (
      <div data-testid={valueTestId}>{valueContent}</div>
    );
  if (layout === "horizontal") {
    return (
      <div className="flex lg:max-w-[285px] xl:max-w-[325px] relative mb-px group">
        <div className="flex my-1 leading-tight space-x-2">
          <span
            className={`${labelClass} w-[100px] flex-shrink-0`}
            data-testid={spanTestId}
          >
            {label}
          </span>
          {valueEl}
        </div>
        <hr className="h-px border-0 w-full block absolute bottom-0 bg-gradient-to-r from-gray-100 via-gray-100 to-gray-50 group-last:hidden" />
      </div>
    );
  }
  return (
    <div className="my-4 first:mt-0">
      <span className={`${labelClass}`} data-testid={spanTestId}>
        {label}
      </span>
      {valueEl}
    </div>
  );
};

export { Detail };
