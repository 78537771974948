import type {
  EditorState,
  TicketView,
  TicketViewEditorAPI,
  TicketViewEditorProviderProps,
} from "./TicketViewEditor.types";
import { useCallback, useEffect, useMemo, useState } from "react";
import isEqual from "lodash/isEqual";
import { useDamagePreventionAuth } from "@/hooks";
import { writeDirtyView } from "./TicketViewEditorStorage";
import { TicketViewEditorContext } from "./TicketViewEditor.context";
import {
  buildShowTaskFn,
  findFilterIndex,
  isFilterValid,
  toggleSortOrderDirection,
} from "./TicketViewEditorProvider.utils";
import {
  defaultView,
  savedViewToTicketView,
  ticketViewToSavedView,
} from "./TicketViewEditorProvider.constants";

const TicketViewEditorProvider = ({
  savedView,
  initialCleanView,
  initialDirtyView,
  children,
  onDirtyViewChange,
}: TicketViewEditorProviderProps) => {
  const { currentUser } = useDamagePreventionAuth();
  const initializeViews = useCallback(() => {
    let cleanView;
    let dirtyView;
    if (initialCleanView && savedView) {
      throw new Error(
        "Using initialCleanView while using savedView is not supported."
      );
    }
    if (savedView) {
      cleanView = savedViewToTicketView(savedView);
      dirtyView = initialDirtyView ?? { ...cleanView };
    } else {
      cleanView = initialCleanView ? { ...initialCleanView } : defaultView;
      dirtyView = initialDirtyView ? { ...initialDirtyView } : { ...cleanView };
    }
    const isInitialized =
      (savedView || initialCleanView || initialDirtyView) !== undefined;
    return { cleanView, isInitialized, dirtyView };
  }, [savedView, initialCleanView, initialDirtyView]);

  const [{ cleanView, isInitialized, dirtyView }, setState] =
    useState<EditorState>(initializeViews);

  useEffect(() => setState(initializeViews()), [setState, initializeViews]);

  // Setter for manipulating the dirtyView directly.
  const setDirtyView = useCallback(
    (fnOrVal: TicketView | ((view: TicketView) => TicketView)) => {
      if (typeof fnOrVal === "function") {
        const newDirty = fnOrVal(dirtyView);
        setState((state) => ({
          ...state,
          dirtyView: newDirty,
        }));
        onDirtyViewChange && onDirtyViewChange(newDirty);
      } else {
        setState((state) => ({ ...state, dirtyView: fnOrVal }));
        onDirtyViewChange && onDirtyViewChange(fnOrVal);
      }
    },
    [dirtyView, setState, onDirtyViewChange]
  );

  const {
    searchTerm,
    filters,
    excludeFilters,
    columns,
    order,
    name,
    isShared,
    isShowingTasks,
  } = dirtyView;

  const isClean = isEqual(dirtyView, cleanView);

  const api: TicketViewEditorAPI = useMemo(
    () => ({
      cleanView,
      dirtyView,
      savedView,
      resetView: () => {
        writeDirtyView();
        setDirtyView({ ...cleanView });
      },
      isClean,
      isDirty: !isClean,
      isInitialized,
      name,
      setName: (val) => setDirtyView({ ...dirtyView, name: val }),
      resetName: () => setDirtyView({ ...dirtyView, name: cleanView.name }),
      isShared,
      isShowingTasks,
      setIsShared: (val) => setDirtyView({ ...dirtyView, isShared: val }),
      filters,
      excludeFilters,
      columns,
      setDirtyView,
      searchTerm,
      sortOrder: order,
      setSearchTerm: (val) => {
        // No need to re-trigger if the same value is already there
        // [DPAPP-651]
        if (!isEqual(dirtyView?.searchTerm, val)) {
          setDirtyView({ ...dirtyView, searchTerm: val });
        }
      },
      setSortOrder: (val) => {
        const order = val
          ? { field: val.field, direction: val.direction }
          : undefined;
        setDirtyView({ ...dirtyView, order });
      },
      toggleSortOrderDirection: () => setDirtyView(toggleSortOrderDirection),
      resetOrder: () => setDirtyView({ ...dirtyView, order: cleanView.order }),
      resetFilters: () =>
        setDirtyView({
          ...dirtyView,
          filters: cleanView.filters,
          excludeFilters: cleanView.excludeFilters,
        }),
      addFilter: ({ field, value }) => {
        // Reconstruct the filter so that 1. we own the reference & 2. only allowed fields make it in.
        const filter = { field, value };
        // Add initial exclude filter with a empty value
        const exclude = { field, value: undefined };

        setDirtyView((dirtyView) => ({
          ...dirtyView,
          filters: [filter, ...dirtyView.filters],
          excludeFilters: [exclude, ...dirtyView.excludeFilters],
        }));
      },
      removeFilter: (selector) => {
        const idx = findFilterIndex(selector, filters);
        const idxExclude = findFilterIndex(selector, excludeFilters);

        if (idx === -1 && idxExclude === -1) {
          return undefined;
        }

        const newFilters = [...filters];
        const newExcludeFilters = [...excludeFilters];

        if (idxExclude !== -1) {
          newExcludeFilters.splice(idxExclude, 1);
        }

        if (idx !== -1) {
          newFilters.splice(idx, 1);
        }

        setDirtyView({
          ...dirtyView,
          filters: newFilters,
          excludeFilters: newExcludeFilters,
        });
      },
      clearEmptyFilters: () => {
        // Filter out invalid filters
        const validFilters = filters.filter(isFilterValid);
        const validExcludeFilters = excludeFilters.filter(isFilterValid);

        // Ensure all valid excludeFilters are included in filters
        const finalFilters = [...validFilters];

        validExcludeFilters.forEach((excludeFilter) => {
          if (
            !validFilters.some((filter) => filter.field === excludeFilter.field)
          ) {
            finalFilters.push({ field: excludeFilter.field, value: undefined });
          }
        });

        // Construct the new dirty view
        const newDirtyView = {
          ...dirtyView,
          filters: finalFilters,
          excludeFilters: validExcludeFilters,
        };

        setDirtyView(newDirtyView);

        return newDirtyView;
      },

      replaceFilter: (selector, newFilter, excludeFilter) => {
        const idx = findFilterIndex(selector, filters);
        const idxExclude = findFilterIndex(selector, excludeFilters);

        if (idx === -1 && idxExclude === -1) {
          return undefined;
        }

        const newFilters = [...filters];
        const newExcludeFilters = [...excludeFilters];

        // Update the main filter if found
        if (idx !== -1) {
          newFilters.splice(idx, 1, newFilter);
        }

        // Always update the exclude filter if found, even if excludeFilter isn't provided
        if (idxExclude !== -1) {
          if (excludeFilter) {
            // If excludeFilter is provided, use it
            newExcludeFilters.splice(idxExclude, 1, excludeFilter);
          } else {
            // If excludeFilter isn't provided, create a corresponding one with the same field
            // but maintain the existing value unless the field changed
            const currentExcludeFilter = excludeFilters[idxExclude];
            const newExcludeFilterValue =
              currentExcludeFilter?.field === newFilter.field
                ? currentExcludeFilter.value
                : undefined;

            newExcludeFilters.splice(idxExclude, 1, {
              field: newFilter.field,
              value: newExcludeFilterValue,
            });
          }
        } else if (idx !== -1) {
          // If we found a main filter but no corresponding exclude filter,
          // add a new exclude filter with the same field
          newExcludeFilters.push({ field: newFilter.field, value: undefined });
        }

        setDirtyView({
          ...dirtyView,
          filters: newFilters,
          excludeFilters: newExcludeFilters,
        });
      },
      addColumn: ({ field }) => {
        // Reconstruct the filter so that 1. we own the reference & 2. only allowed fields make it in.
        const column = { field };

        setDirtyView((dirtyView) => ({
          ...dirtyView,
          columns: [...dirtyView.columns, column],
        }));
      },
      removeColumn: (selector) => {
        const idx = findFilterIndex(selector, columns);
        if (idx === -1) {
          return undefined;
        }
        const newColumns = [...columns];
        const [removed] = newColumns.splice(idx, 1);
        setDirtyView({ ...dirtyView, columns: newColumns });
        return removed;
      },
      moveColumn: (selector, targetIdx) => {
        const idx = findFilterIndex(selector, columns);
        if (idx === -1) {
          return undefined;
        }
        const newColumns = [...columns];
        const [removed] = newColumns.splice(idx, 1);
        newColumns.splice(targetIdx, 0, removed!);
        setDirtyView({ ...dirtyView, columns: newColumns });
      },
      resetColumns: () =>
        setDirtyView({ ...dirtyView, columns: cleanView.columns }),
      toSavedView: ticketViewToSavedView,
      toggleIsShowingTasks: () =>
        setDirtyView({
          ...dirtyView,
          isShowingTasks: !dirtyView.isShowingTasks,
        }),
      showTask: buildShowTaskFn(filters, currentUser),
    }),
    [
      cleanView,
      dirtyView,
      savedView,
      isClean,
      isInitialized,
      name,
      isShared,
      isShowingTasks,
      filters,
      excludeFilters,
      columns,
      setDirtyView,
      searchTerm,
      order,
      currentUser,
    ]
  );

  return (
    <TicketViewEditorContext.Provider value={api}>
      {children}
    </TicketViewEditorContext.Provider>
  );
};

export { toggleSortOrderDirection, buildShowTaskFn, TicketViewEditorProvider };
